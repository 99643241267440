form input[type="submit"],
form button[type="submit"] {
	height: 2.5em;
	line-height: 2.4em;
	background-color: @oebb-red;
	color: @white;
	border:1px solid @oebb-red;
	text-transform: uppercase;
	font-weight: bold;
	position: relative;
	top: 2px;
	padding: 0 5px 0 5px;

	&:focus {
		outline: 1px dotted white;
		outline-offset: -4px;
	}

	&:active {
		background-color:  @white;
		color: black;
	}

	&:hover {
		background-color:  @white;
		color: @oebb-red;
	}
}


form input[type="submit"]:disabled,
form input[type="submit"]:disabled:hover,
form button[type="submit"]:disabled,
form button[type="submit"]:disabled:hover {
	background-color: @oebb-red;
	color: @white;
	opacity:0.5;
}

form input[type="checkbox"] {
	margin-right: 10px;
}

p.error {
	color: @error;
}

p.success {
	color: @success;
}



.main-content form {
	.oebb-input-with-icon {
		position: relative;

		& > [class^="ic-"],
		& > [class*=" ic-"] {
			position: absolute;
			top: 50%;
			left: 8px;
			z-index: 1;

			transform: translateY(-50%);
		}

		.oebb-input {
			padding-left: 30px;
		}
	}

	.oebb-input {
		display: inline-block;
		height: 65px;
		padding: 10px 12px;
		box-sizing: border-box;

		background-color: @black-5;
		border: none;
		box-shadow: 0 1px 0 @black-55;

		font-family: inherit;

		&:focus {
			box-shadow: 0 2px 0 @oebb-red;
		}

		&:disabled {
			background-color: @black-20;
			cursor: not-allowed;
		}

		&::before {
			content: '';
			display: inline-block;
			vertical-align: middle;
			height: 100%;
		}
	}
}
