
@keyframes dotPulse {
	0% {
	  opacity: 0;
	  transform: scale(0);
	}
	20% {
	  opacity: 1;
	  transform: scale(1);
	}
	70%,
	100% {
	  opacity: 0;
	  transform: scale(0);
	}
  }

.gallery {
	position: relative;
	margin-bottom: 20px;

	.gallery__dotts-stage {
		display: none;
	}

	&.gallery--loading {
		.gallery__dotts-stage {
			display: flex;
			position: absolute;
			top: 50px;
			left: 50%;
			transform: translateX(-50%);
		}
		.gallery__dott {
			width: 15px;
			height: 15px;
			background: black;
			border-radius: 50%;
			margin: 5px;
			animation: dotPulse 1.5s infinite linear;

			&--1 { animation-delay: 0s;}
			&--2 { animation-delay: .25s;}
			&--3 { animation-delay: .5s;}
		}

		.gallery__list {
			opacity: 0;
		}
	}



	.content ul.accordeon & .gallery__list, // CORP-Accordeon Überschreiben
	.gallery__list {
		list-style: none;
		margin: 0;
		padding: 0;

		display: flex;
		flex-wrap: wrap;
		opacity: 1;
		transition: opacity .25s ease;
	}

	.gallery__list-item {
		flex-grow: 0;
		margin: 0;
		padding: 0;
		font-size: 0; // avoid white space
		border: 1px solid white;
	}

	.gallery__thumbnail-link {
		display: block;
		position: relative;
		overflow: hidden;

		&:before {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: rgba(0,0,0,.5);
			z-index: 1;
			opacity: 0;
			transition: opacity .25s ease;
		}

		&:after {
			content: "";
			display: block;
			width: 20px;
			height: 20px;
			background: url(../../images/zoom.png);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 1;
			opacity: 0;
			transition: opacity .25s ease;
		}

		&:hover,
		&:focus {
			.gallery__thumbnail {
				transform: scale(1.1);
			}

			.gallery__thumbnail-caption,
			&:before,
			&:after {
				opacity: 1;
			}
		}
	}

	.gallery__thumbnail {
		margin: 0;
		padding: 0;
		transition: transform .25s ease;
		display: block;
		width: auto;
	}

	.gallery__thumbnail-caption {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		background: #000;
		color: white;
		font-size: 14px;
		padding: 5px;
		opacity: 0;
		transition: opacity .25s ease;
		z-index: 2;
	}
}


.gallery__edit-wrapper {
	max-width: 200px;
}

//overwriting content styles
.magazine .gallery,
.gallery {
	oebbx-gallery-thumbs, oebbx-gallery-slider {
		ul {
			padding: 0;
			margin: 0;

			li {
				margin: 0;
			}
		}

		img {
			margin: 0;
			width: auto;
			min-width:100%;
		}
	}

	.gallery__download-wrapper {
		margin-top: 20px;
	}

	.oebb-icon {
		fill: currentColor;
	}
}

.edit-mode {
	.gallery-slider-area-pictures-container {
		display: block;
	}

	.gallery-slider-area-pictures-view {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		gap: 0.125rem;
	}

	.gallery-slider-area-picture-container {
		position: relative;

		display: grid;
		max-width: 100%;
		min-height: 6em;
		aspect-ratio: 3 / 2;
		overflow: hidden;

		img {
			position: absolute;
			top: 50%;

			min-width: auto;
			max-width: 100%;
			height: 100%;

			transform: translateY(-50%);
			object-fit: cover;
		}
	}
}
