.co2 {
	font-size: 24px;
	text-align: center;
}

.co2__intro,
.content .co2__intro {
	margin-bottom: 0.5em;
}

.co2__counter {
	color: #86b14c;
	font-size: 40px;
	font-weight: bold;
}

.co2--in-slider {
	text-align: left;

	.co2__counter {
		margin: 8px 0;

		font-size: 24px;
	}

	@media @only-small-devices {
		font-size: 16px;
	}
}

.co2--in-row {
	text-align: right;

	.co2__intro {
		margin: 1rem 0;
	}
}

.co2--left {
	text-align: left;
}

.co2--center {
	text-align: center;
}

.co2--right {
	text-align: right;
}

.co2--flipped {
	display: flex;
	flex-direction: column-reverse;
}

.co2--margin-top {
	margin-top: 20px;
}

.co2--bigger {
	font-size: 48px;

	.co2__intro {
		font-size: 28px;
	}

	.co2__counter {
		font-size: 64px;
	}
}
