/** ".is-tabbing" is added via JavaScript and shows the outline */
.is-tabbing {
	--oebb-tabbing-outline: 2px dotted @oebb-red;
	--oebb-tabbing-outline-offset: 2px;

	button::-moz-focus-inner {
		border: 0;
	}

	.lg-actions > div:focus,
	.lg-toolbar > span:focus,
	.oebb-dropdown-panel__btn-close--active,
	oebb-fm-radiofield .fm-field label.active,
	.fm-field label.active,
	textarea:focus,
	select:focus,
	//input[type="submit"],
	input[type="submit"]:focus,
	input[type="button"]:focus,
	input[type="text"]:focus,
	a:focus,
	button:focus,
	div:focus {
		outline: var(--oebb-tabbing-outline) !important;
		outline-offset: var(--oebb-tabbing-outline-offset) !important;
		z-index: 10 !important;
	}

	.mup-content:focus,
	.slider li a:focus {
		outline-offset: -6px !important;
	}

	oebb-popover {
		--oebb-popover-outline: var(--oebb-tabbing-outline);
		--oebb-popover-outline-offset: var(--oebb-tabbing-outline-offset);
	}

}
