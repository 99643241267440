.md-teaser-slider {
	margin: 0 0 20px 0;
}

.main-content .md-teaser-slider ul {
	margin-bottom: 0;
}

.main-content .md-teaser-slider ul.teaser-slider-list > li a:focus {
	outline: none!important;
}

.main-content .md-teaser-slider ul.teaser-slider-list > li a:focus > div,
.main-content .md-teaser-slider ul.teaser-slider-list > li a:focus ~ div{
	outline: 2px dotted @oebb-red;
	outline-offset: -3px;
}

.main-content .md-teaser-slider ul.teaser-slider-list > li a:hover .teaser-slider-title,
.main-content .md-teaser-slider ul.teaser-slider-list > li a:focus .teaser-slider-title{
	color: @oebb-red;
	text-decoration: underline;
}

/* slider actions */
.row {
	.lSAction > button {
		opacity: 1;
		background-color: @oebb-red;
		background-image: none;
		margin-top: -22px;
		color: @white;
		width: 44px;
		height: 44px;
		font-size: 24px;
		text-align: center;
		text-decoration: none;
		border: none;
	}

	.lSAction > button:focus,
	.lSAction > button:hover{
		background-color: @white;
	}

	.lSAction > button:focus{
		outline: 1px dotted @oebb-red;
	}

	.md-teaser-slider--btn-actions-non-standard .lSAction > button {
		background-color: #eee;
		color: @oebb-red;


		&:focus,
		&:hover {
			background-color: @white;
		}
	}

	.lSAction > button:focus span,
	.lSAction > button:hover span{
		color: @oebb-red;
	}

	.lSAction > .lSPrev {
		-webkit-border-top-right-radius: 5px;
		-webkit-border-bottom-right-radius: 5px;
		-moz-border-radius-topright: 5px;
		-moz-border-radius-bottomright: 5px;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
		left: 0;
	}
	.lSAction > .lSNext {
		-webkit-border-top-left-radius: 5px;
		-webkit-border-bottom-left-radius: 5px;
		-moz-border-radius-topleft: 5px;
		-moz-border-radius-bottomleft: 5px;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		right: 0;
	}
	.lSAction > button span {
		display: block;
	}
}
.teaser-slider-list li a {
	text-decoration: none;
	color: inherit;
}
.teaser-slider-cat {
	text-transform: uppercase;
	font-size: 18px;
	color: @black-55;
}

.teaser-slider-title:not(.teaser-slider-title--no-truncation) {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.main-content h3.teaser-slider-title {
	font-size: 22px;
	line-height: 26px;
	overflow: hidden;
	margin-bottom: 0.6em;
	margin-top: 5px;

	@media @screen-lg-min {
		height: 52px;
		margin-top: 1em;
	}

	.teaser-slider-title__date {
		font-size: .8em;
		color: @black-55;
		white-space: nowrap;
	}
}


.image-wrapper--15-10 {
	height: 0;
	padding-top: ~'calc(100% / 1.5)';
	position: relative;
	overflow: hidden;

	> img {
		position: absolute;
		min-height: 100%;
		min-width: 100%;
		max-height: 100%;
		width: auto;
		height: auto;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transition: transform .75s ease;

		.teaser-slider-list li a:hover &,
		.teaser-slider-list li a:focus & {
			transform: scale(1.05) translate(-50%, -50%);	
			
		}
	}

	
}

.teaser-slider {
	padding: 3px;
}
