.iframe-overlay {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, .8);
	z-index: 999999999;
}

.iframe-overlay a {
	display: block;
	position: absolute;
	top: 6px;
	right: 18px;
	z-index: 2;
	font-size: 30px;
	color: #999;
	text-decoration: none;
	font-weight: bold;
}

.iframe-overlay a:focus,
.iframe-overlay a:active,
.iframe-overlay a:hover
{
	color: #111;
}
.iframe-overlay h3 {
	display: block;
	width: 100%;
	margin:0;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	color: #000;
	height: 50px;
	z-index: 2;
	background: white;
	line-height: 50px;
	text-align: center;
}

.iframe-overlay h3:after {
	content: '';
	-webkit-box-shadow: -15px 0 10px 0 rgba(0, 0, 0, .4);
	-moz-box-shadow: -15px 0 10px 0 rgba(0, 0, 0, .4);
	box-shadow: -15px 0 10px 0 rgba(0, 0, 0, .4);
	display: block;
	position: absolute;
	top: 50px;
	height: 10px;
	width: 110%;
	left: -5%;
}

.iframe-overlay .iframe-holder {
	display: block;
	position: fixed;
	top: 5%;
	left: 50%;
	margin-top: 0;
	margin-left: -280px;
	height: 90%;
	width: 560px;
	background: white;

	overflow: auto!important;
	-webkit-overflow-scrolling: touch!important;
}

@media @only-small-devices {
	.iframe-overlay .iframe-holder {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		margin-top: 0;
		margin-left: 0;
		height: 100%;
		width: 100%;
		background: white;
		padding: 0;


	}
}

.iframe-overlay.iframe-overlay-fullscreen  .iframe-holder {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	margin-top: 0;
	margin-left: 0;
	height: 100%;
	width: 100%;
	background: white;
	padding: 0;
}


.iframe-overlay iframe {
	display: block;
	position: relative;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	padding-top: 50px;
	padding-bottom: 0;
	background: white;
	overflow:scroll!important;


	-webkit-overflow-scrolling: touch!important;
}

iframe {
	border: none;
}