@import 'variables.less';

.infobox {
    padding: 1rem;
    font-size: 1.375rem;
    background: @black-5;
    margin-bottom: 1.875rem;
    position: relative;
    margin-top: 5rem;

    &__icon {
        color: @oebb-red;
        display: block;
        width: 2.5rem;
        height: 2.2rem;
        background: @black-5;
        position: absolute;
        top: 0;
        left: 1rem;
        transform: translateY(-100%);
        display: flex;
        justify-content: center;
        align-items: flex-end;
        font-size: 1.8rem;
    }

    p {
        margin-top: 0;
    }

    small {
        font-size: 1rem;
    }
}