.content-image--mobile {
    display: none;

    @media @only-small-devices{
		display: block;
	}
}

.content-image--tablet {
    display: none;

    @media @only-small-devices{	
        &.content-image--mobile {
            display: block;
        }
    }

    @media @only-medium-devices{
		display: block;
	}
}

.content-image--desktop {
    display: none;

    @media @only-medium-devices{	
        &.content-image--tablet {
            display: block;
        }
    }

    @media @large-devices-and-grater {
		display: block;
	}
}