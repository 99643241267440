.mashup {
	background: @black-5;
	padding: 20px 0 20px 0;
	margin-bottom: 10px;
}

.mashup ul {
	display: block;
	position: relative;
	padding: 0;
	margin: 0 20px 0 0;
}

.mashup ul img {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 440px;
	height: 256px;

}

.mashup ul img.center {
	left: 50%;
	margin-left: -220px;
}

.mashup ul img.visual-center {
	right: auto;
	left: 0;
	width: auto;
	height: 256px;
	margin-left: 0;
}

.mashup ul [class^="col-"] {
	margin-bottom: 20px;
	padding: 0;

}

/*
.mashup img.visual-center {
	right: auto;
	left: 0;
	margin-left: 0;
}

.mashup img.center {
	right: auto;
	left: 50%;
	margin-left: -567px;
}

.mashup img.left {
	right: auto;
	left: 0;
	margin-left: 0;
}

.mashup img.right {
	left: auto;
	right: 0;
	margin-left: 0;
}
*/

.mup-col-1, .mup-col-2, .mup-col-3, .mup-img-left {
	position: relative;
	background: transparent none repeat scroll 0 0;
	height: 256px;
	border-left: 20px solid @black-5;
	overflow: hidden;
	max-width: 440px;
	margin: auto;
}

@media screen and (min-width: 567px) and (max-width: 860px) {
	.col-1.mup-col-1 {
		width: 50%;
		float: left;
	}
}

.mup-content {
	background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 33%, rgba(0, 0, 0, 0.73) 66%, rgba(0, 0, 0, 0.73) 100%) repeat scroll 0 0;
	color: white;
	display: block;
	height: 256px;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	padding: 0;
	margin: 0;
	text-decoration: none;
}

.mup-content:focus, .mup-content:hover {
	background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.33) 66%, rgba(0, 0, 0, 0.9) 100%) repeat scroll 0 0;
	text-shadow: 1px 1px 1px #000;
}

.mup-content:focus {

	outline: 1px dotted #ccc;
	outline-offset: -6px;
}

.mup-content:active {
	background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.66) 66%, rgba(0, 0, 0, 0.9) 100%) repeat scroll 0 0;
}

.mup-statement {
	background: rgba(0, 0, 0, 0) repeat scroll 0 0;
	color: @black-70;
}

.mup-statement:focus, .mup-statement:hover {
	background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 66%, rgba(0, 0, 0, 0.3) 100%) repeat scroll 0 0;
	text-shadow: none;
}

.mup-statement:active {
	background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 66%, rgba(0, 0, 0, 0.3) 100%) repeat scroll 0 0;
}

.mup-content > div {
	width: 100%;
}

.mup-content h3, 
.mup-content h3.white-header {
	color: @white;
	text-shadow: 1px 1px 1px #000;
	margin: 20px;
	text-transform: uppercase;
	font-size: 0.85em;
	
	&.mup-title {
		background: rgba(0,0,0,.5);
		width: 100%;
		padding: 10px 20px;
		margin: 0;
	}
}


.mup-content h3.red-header {
	color: @oebb-red;
	text-shadow: none;
}

.mup-content img.center {
	display: block;
	height: 356px;
	left: 50%;
	right: auto;
	margin-left: -567px;
	position: absolute;
	top: 0;
	width: 1134px;
}

.mup-multiline-truncation {
	position: absolute;
	bottom: 30px;
	margin: 20px;
	font-size: 1.15em;
	font-weight: bold;
}

.mup-category-icon {
	position: absolute;
	bottom: 20px;
	height: 15px;
	color: @black-20;
	width: 100%;
	margin: 0 20px 0 20px;
}

.mup-statement .mup-category-icon {
	color: @black-70;
}

.mup-category-icon span {
	padding-right: 5px;
}

.mup-category-icon span.mup-ic-right {
	display: inline-block;
	float: right;
	margin-right: 40px;
	padding: 0 0 0 5px;
}

.mup-more-button {
	margin: -30px auto 0 auto;
	text-align: center;
	padding-bottom: 10px;
}

.mup-more-button .button-text-box {
	display: inline-block;
	margin: 0;
	text-align: center;
}

a.button-more {
	display: inline-block;
	background-color: @oebb-red;
	padding: 10px;
	color: #FFFFFF;
	text-decoration: none;
	border: 1px solid #fff;
}

a.button-more:hover, a.button-more:active, a.button-more.md-button:focus {
	color: @oebb-red;
	background-color: white;
	border: 1px solid @oebb-red;
}

a.button-more:focus {
	outline: 1px dotted white;
	outline-offset: -4px;
}

a.button-more:active {
	color: black;
}

a.button-more .ic-plus1 {
	line-height: 1.15em;
}

/*  categorylist*/
.mup-category-list {
	margin: 0 auto;
	text-align: center;
	width: 100%;
	max-width: 1200px;
	min-height: 52px;
	overflow: hidden;
	position: relative;
	z-index: 3;
	overflow-x: auto;
}

.mup-category-list ul {
	list-style: none;
	margin: 0;
	padding: 0;
	white-space: nowrap;
	overflow: visible;
	display: inline-block;
	position: relative;
	left: 0;

}

.mup-category-list ul.animation {

	-webkit-transition: left .5s;
	-moz-transition: left .5s;
	transition: left .5s;
}

.mup-category-list ul li {
	list-style: none;
	position: relative;
	display: inline-block;
	white-space: nowrap;
	padding: 0 10px;
	border-left: solid 1px @black-20;

	&.active:after {
		content: "";
		width: 0;
		height: 0;
		position: absolute;
		bottom: 0;
		left: 50%;
		border-style: solid;
		border-width: 10px 10px 0 10px;
		border-color: @oebb-red transparent transparent transparent;
		transform: translate(-50%, 100%);
	}
}

.mup-category-list ul li:first-child {
	border-left: solid 1px transparent;
}

.mup-category-list ul li .mashup-categorie-tab {
	display: inline-block;
	padding: 10px;
	text-decoration: none;
	font-size: 1.0em;
	font-weight: bold;
	color: @black-40;
	max-height: 38px;
	height: 38px;
	background: transparent;
	border: none;

	&:active {
		background: transparent;
		border: none;
	}
}

.mup-category-list ul li.active .mashup-categorie-tab {
	background-color: @oebb-red;
	color: @white;
}

.mup-category-list ul li a:hover,
.mup-category-list ul li a:focus {
	background-color: @oebb-red;
	color: @white;
}

.mup-category-list ul li a:focus {
	outline: 1px dotted white;
	outline-offset: -4px;
}

.mup-arrow-down {
	display: block;
	position: absolute;
	bottom: -10px;
	width: 0;
	height: 0;
	margin-left: -9px;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid @oebb-red;
	left: 50%;
}

.mup-next-btn {
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	height: 38px;
	width: 38px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border: 0;
	background-color: @black-70;
	color: white;
	outline: 0;
	text-align: center;
	padding-left: 0;
	padding-right: 0;
	z-index: 999;
}

.mup-next-btn:focus, .mup-next-btn:hover {
	background-color: @oebb-red;
}

.mup-next-btn-decorator {
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	height: 52px;
	width: 52px;
	background: white;
	background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}

.mup-prev-btn {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	height: 38px;
	width: 38px;
	border: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	border: none;
	z-index: 999;
	background-color: #4C4C4C;
	color: white;
	outline: 0;
	text-align: center;
	padding-left: 0;
	padding-right: 0;
}

.mup-prev-btn:focus, .mup-prev-btn:hover {
	background-color: @oebb-red;
}

.mup-prev-btn-decorator {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	height: 52px;
	width: 52px;
	background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@media @medium-devices-and-smaller {
	.mup-next-btn {
		width: 28px;
	}

	.mup-prev-btn {
		width: 28px;
	}
}

.mup-facebook, .mup-twitter {
	background: white;

}

.mup-social-media {
	padding: 0 20px;
}

a.mup-social-media-btn-disable, p a.mup-social-media-btn-disable {
	display: block;
	position: absolute;
	right: 0;
	bottom: 0;
	margin: 0;
	width: 24px;
	height: 24px;
	line-height: 24px;
	margin: 0;
	padding: 0;
	border-top-left-radius: 10px;
}

a.mup-social-media-btn-disable span.ic-close2 {
	display: inline-block;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -8px;
	margin-top: -9px;

}

p.center-text {
	text-align: center;
}

.mup-content h3.rcg-raute span {
	position: relative;
}

.mup-content h3.rcg-raute {

	width: auto;
	display: inline-block;
	height: 21px;
	line-height: 21px;
	background: #e1002a;
	/* Skew */
	-webkit-transform: skew(-20deg);
	-moz-transform: skew(-20deg);
	-o-transform: skew(-20deg);
	transform: skew(-20deg);
	z-index: 0;
	margin: 10px 20px 20px 15px;
	padding-left: 15px;
	padding-right: 15px;
}

.mup-content h3.rcg-raute span {
	display: inline-block;
	-webkit-transform: skew(20deg);
	-moz-transform: skew(20deg);
	-o-transform: skew(20deg);
	transform: skew(20deg);
	text-shadow: none;
}

/* Immo-Site mashup styles */
.mup-content h3.immo-mup-up {
	position: absolute;
}

.mup-content h3.immo-mup-down{
	position: absolute;
	font-weight: normal;
	color: @black-20;
	bottom: 0;
	text-transform: none;
}

.mup-content h3.immo-mup-down.immo-mup-down-right {
	right: 0;
}

.container .mashup-filter {
	width: auto;
	margin-top: -20px;
	margin-left: ~'calc((100vw - 100%) / 2 * -1)';
	margin-right: ~'calc((100vw - 100%) / 2 * -1)';
	margin-bottom: 15px;
	
}