@media print {
	@page :left {
		margin-left: 2.5cm;
		margin-right: 1.5cm;
		margin-top: 2.5cm;
		margin-bottom: 2cm;
	}
	@page :right {
		margin-left: 1.5cm;
		margin-right: 2.5cm;
		margin-top: 2.5cm;
		margin-bottom: 2cm;
	}

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        page-break-after: avoid;
    }

	#skiplinks,
	#off-canvas-handler,
	#off-canvas-menu,
	#konzern-nav,
	#konzern-nav-link,
	.channel-nav,
	header .nav-bar,
	footer,
	.footer-row .row .col-2 ul,
	.social-row,
	.slider-action-link,
	.slider-point-panel,
	.mup-next-btn-decorator,
	.mup-next-btn,
	.mup-prev-btn-decorator,
	.mup-prev-btn,
	.mup-more-button,
	.lSAction,
	.slider-content-link-panel,
	.md-parallax,
	.parallax-mirror,
	a.md-button,
	ul.tab-handler,
	ul.tab-handler-dropdown ul.accordeon > li > a span[class^="ic-"] {
		display: none !important;
	}

	header {
		background: none;
		position: static;
		width: auto;
		z-index: 1;
		margin-bottom: 16pt;
	}
	header .container-top {
		height: auto;
	}
	.container {
		padding: 0;
		position: static !important;
		max-width: 100% !important;
	}
	.row {
		margin: 0;
	}
	[class^=col-] {
		padding-left: 0;
	}
	.logo-link {
		display: block;
		position: static;
		top: 0;
		left: 0;
	}
	.breadcrumb {
		position: static;
		width: auto;
	}

	.content {
		padding-top: 0;
	}
	.main-content {
		left: 0;
		min-height: 0;
		padding-bottom: 0;
		padding-top: 0;
		position: static;
	}
	.main-content .container .row .col-1 {
		display: none;
	}
	.main-content .container .row .col-3 {
		width: 100%;
	}
	.main-content .container .content .row .col-1,
	.main-content .container .mashup .row .col-1 {
		display: block;
	}
	.main-content .container .content .row .col-3 {
		width: 75%;
	}

	/* accordeon */
	ul.accordeon > li > div {
		display: block !important;
	}

	/* bühnen slider */
	 .main-content .slider {
		 width: 100% !important;
		 height: auto !important;
		 position: static;
		 left: 0 !important;
		 margin-left: 0 !important;
	 }
	.main-content .slider ul li {
		display: block !important;
		position: static !important;
		width: auto !important;
		height: auto !important;
		margin-bottom: 24pt;
	}

	.main-content .slider img.slider-img {
		position: static !important;
		width: 30% !important;
		height: auto !important;
		top: 0;
		left: 0 !important;
		margin-left: 0 !important;
		transform: none;
	}
	.main-content .slider li a {
		width: 100% !important;
		height: auto !important;
		text-decoration: none;
		position: relative;
	}
	.main-content .slider .slider-content-decorator {
		display: none;
	}
	.main-content .slider .slider-content {
		position: static;
		width: auto;
		height: auto;
	}
	.main-content .slider .slider-content h2 {
		margin-top: 0.25em;
		color: #000000;
		font-size: 1.5em;
	}
	.main-content .slider .slider-content-text {
		position: static;
		width: auto;
		height: auto;
		padding: 0;
	}
	.main-content .slider .slider-progress-state {
		display: none;
	}
	.main-content .slider .slider-progress-bar {
		position: static;
	}
	.main-content .slider .slider-progress-bar span {
		padding: 0;
	}

	/*teaser slider */
	.main-content .md-teaser-slider ul {
		width: 100% !important;
		height: auto !important;
		transform: initial !important;
		page-break-inside: auto;
		orphans: 3;
		widows: 5;
	}
	.main-content .md-teaser-slider ul li {
		margin-bottom: 8pt !important;
		page-break-inside: avoid;
		orphans: 3;
		widows: 5;
	}
	.main-content h3.teaser-slider-title {
		margin-top: 0 !important;
		margin-bottom: 0.6em;
	}

	/* mash-up */
	.mup-category-list {
		margin: 0;
		max-width: 100%;
		min-height: 0;
		overflow: visible;
		position: static;
		text-align: center;
		width: 100%;
		margin-bottom: 8pt;
	}
	.mup-category-list ul {
		display: block;
		left: 0;
		list-style: outside none none;
		margin: 0;
		overflow: visible;
		padding: 0;
		position: static;
		white-space: normal;
	}
	.mup-category-list ul li {
		border-left: 1px solid #cccccc;
		display: inline-block;
		list-style: outside none none;
		padding: 0 8pt;
		position: static;
		white-space: normal;
	}
	.mashup {
		background: none;
		margin-bottom: 8pt;
		padding: 0;
	}
	.mashup ul {
		display: block;
		margin: 0;
		padding: 0;
		position: static;
		page-break-inside: auto;
		orphans: 3;
		widows: 5;
	}
	.mup-content {
		background: none !important;
	}

		/* content*/
	.md-headline-teaser {
		padding-bottom:  0 !important;
		margin-bottom:  0 !important;
	}
	.md-text-img, .md-img-text, .md-img-text-teaser, .md-img-headline-teaser {
		margin:  0 !important;
	}
	.main-content .md-img-orig-width img {
		width: 100% !important;
	}
	ul.tabs.tabs-default  {
		display: block !important;
		page-break-inside: auto;
		orphans: 3;
		widows: 5;
	}
	ul.tabs.tabs-default li {
		display: block !important;
		page-break-inside: auto;
		orphans: 3;
		widows: 5;
	}

	h2.tab-title{
		position: relative !important;
		top: initial !important;
		left: initial !important;
	}

	/* footer */
	.footer-row .row div:first-child {
		font-weight: bold;
		margin-top: 0;
		width: 100%;
	}
	.footer-row .row div:last-child {
		width: 0;
	}
}
