.loader {
	overflow: hidden;
	font-size: 10px;
	margin: 50px auto;
	text-indent: -9999em;
	width: 7em;
	height: 7em;
	border-radius: 50%;
	background: #cc0000;
	background: -moz-linear-gradient(left, #cc0000 10%, rgba(255, 255, 255, 0) 42%);
	background: -webkit-linear-gradient(left, #cc0000 10%, rgba(255, 255, 255, 0) 42%);
	background: -o-linear-gradient(left, #cc0000 10%, rgba(255, 255, 255, 0) 42%);
	background: -ms-linear-gradient(left, #cc0000 10%, rgba(255, 255, 255, 0) 42%);
	background: linear-gradient(to right, #cc0000 10%, rgba(255, 255, 255, 0) 42%);
	position: absolute;
	top:7%;
	left:50%;
	-webkit-animation: load3 1.4s infinite linear;
	animation: load3 1.4s infinite linear;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}
.loader-xs {
	width: 2em;
	height: 2em;
	margin-left: -1em;
}
.loader-s {
	width: 4em;
	height: 4em;
	margin-left: -2em;
}
.loader-m {
	width: 6em;
	height: 6em;
	margin-left: -3em;
}
.loader-l {
	width: 8em;
	height: 8em;
	margin-left: -4em;
}
.loader-xl {
	width: 10em;
	height: 10em;
	margin-left: -5em;
}
.loader:before {
	width: 50%;
	height: 50%;
	background: #cc0000;
	border-radius: 100% 0 0 0;
	position: absolute;
	top: 0;
	left: 0;
	content: '';
}
.loader:after {
	background: white;
	width: 75%;
	height: 75%;
	border-radius: 50%;
	content: '';
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
@-webkit-keyframes load3 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load3 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
