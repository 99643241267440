/* Konzern-Navigation */

#konzern-nav-link {
	display: inline-block;
	position: relative;
	background: @oebb-red;
	color: @white;
	font-family: "Frutiger Next Light", "Source Sans Pro Light", Arial;
	font-size: 18px;
	font-weight: bold;
	padding: 10px 12px;
	text-decoration: none;
	right: 0;
	border: none;
	z-index: 3;

	.skewed-header & {
		margin-left: 15px;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 30px;
			height: 100%;
			background: @oebb-red;
			transform: translateX(-12px) skew(-30deg);
			z-index: -1;

			.rola&,
			.rch&,
			.rcg& {
				transform: translateX(-12px) skew(-22deg);
			}
		  }
	}

	@media @large-devices-and-grater {
		padding: 7px 12px;
	}

}

#konzern-nav-link .ic-orgchart, #konzern-nav-link .ic-sphere {
	display: none;
}

#konzern-nav-link .ic-arrow2-up {
	display: none;
}

.konzern-nav-open #konzern-nav-link .ic-arrow2-up {
	display: inline-block;
}

.konzern-nav-open #konzern-nav-link .ic-arrow2-down {
	display: none;
}

@media @only-small-devices {
	#konzern-nav-link .ic-orgchart, #konzern-nav-link .ic-sphere {
		display: inline-block;
	}

	#konzern-nav-link .text, #konzern-nav-link .ic-arrow2-down, #konzern-nav-link .ic-arrow2-up {
		display: none !important;
	}
}


#konzern-nav-link:focus,
#konzern-nav-link:hover,
#konzern-nav-link:hover:before,
#konzern-nav-link:focus:before {
	background: @black-55;
}

#konzern-nav-link:active {

	color: @black;
}

.konzern-nav-open #konzern-nav-link:focus, .konzern-nav-open #konzern-nav-link:hover {
	background: @black-55;

}



.konzern-nav-open .konzern-nav {
	display: block;
	min-height: 200px;

}

.konzern-nav__title {
	font-size: 36px;
	margin: 0;
	color: white;
	margin-bottom: 10px;
	display: block;
}

.konzern-nav__slogan {
	font-size: 24px;
	margin: 0;
	color: white;
	display: block;
}

.konzern-nav ul li a {
	display: inline-block;
	color: @black-2;
	text-decoration: none;
	width: 100%;
	padding: 8px 8px 8px 0;
	border-top: 1px solid transparent;
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
	border-bottom: 1px solid @black-20;
	white-space: nowrap;
	font-size: 16px;
	font-family: "Frutiger Next Light", "Source Sans Pro Light", Arial;
}

.konzern-nav ul li:last-child a {
	border-bottom: 1px solid transparent;
}

.konzern-nav ul li a:active, .konzern-nav ul li a:focus, .konzern-nav ul li a:hover {
	color: white;
	text-decoration: underline;
}

.konzern-nav ul li a:focus {
	text-decoration: underline;
}

.konzern-nav ul li button {
	background: transparent;
	color: white;
	border: none;
	padding: 8px 8px 8px 0;
	text-decoration: underline;
}

.konzern-nav a span.ic-arrow2-right {
	display: inline-block;
	position: relative;
	top: 0;
	margin-left: -4px;

	width: 14px;
	overflow: hidden;
}

.konzern-nav ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.konzern-nav ul li {
	width: 100%;
	padding-right: 0;
}

.konzern-nav-train {
	display: block;
	position: absolute;
	right: 1px;
	width: 129px;
	height: 91px;
}

@media @medium-devices-and-smaller {
	.konzern-nav-train {
		right: 50px;
	}
}

@media @only-small-devices {
	.konzern-nav-train {
		display: none;
	}
}

.hide-lg {
	@media @screen-lg-min {
		display: none!important;
	}
}