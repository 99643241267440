/* Popovers */

// .md-disclaimer {
// }

.disclaimer-widget {
	display: none;
}

.disclaimer-overlay {
	position: absolute;
	width: 100%;
	display: none;
}

.disclaimer-ready {
	overflow: hidden;
}

.disclaimer-active .disclaimer-widget {
	position: fixed;
	display: block;
	background: #fff;
	padding: 0;
	top: 50%;
	left: 50%;
	height: 450px;
	width: 600px;
	margin-left: -300px;
	margin-top: -225px;
	z-index: 3001;
	opacity: 1;
	filter: alpha(opacity=100);
	overflow-y: hidden;
}

.disclaimer-widget .md-disclaimer-title {
	position: relative;
	top: 0;
	left: 0;
	min-height: 45px;
	width: 100%;
	margin: 0;
	padding: 7px 10px;
	font-size: 16px;
	line-height: 16px;
	color: @white;
	background-color: @oebb-red;
}

.disclaimer-widget .md-disclaimer-content {
	position: relative;
	height: ~"calc(100% - 95px)";
	overflow-y: scroll;
	padding: 20px 0 20px 20px;
}

.disclaimer-widget .md-disclaimer-buttons {
	position: relative;
	height: 50px;
	width: 100%;
	margin: 0;
	padding: 7px 10px;
	font-size: 16px;
	line-height: 16px;
	color: @white;
	background-color: @black-10;
}

.disclaimer-active .disclaimer-overlay {
	display: block;
	top: 0;
	left: 0;
	z-index: 3000;
	opacity: .5;
	filter: alpha(opacity=50);
	height: 20000px;
	// TODO Where is this file?
	background: /*url("../../images/dark_dotted.png")*/ repeat #222;
}

.disclaimer-blur {
	-webkit-filter: blur(3px);
	-moz-filter: blur(3px);
	-ms-filter: blur(3px);
	-o-filter: blur(3px);
	filter: blur(3px);
}

.popover-button {
	display: inline-block;
	background-color: @oebb-red;
	padding: @container-padding-small-devices;
	color: @white;
	font-family: @font-bold;
	text-decoration: none;
	cursor: pointer;
	border: none;
}

.popover-button:hover, .popover-button:active, .popover-button:focus {
	color: @oebb-red;
	background-color: @white;
}
#md-disclaimer-accept {
	float: right;
	margin-left: 20px;
}

@media @only-medium-devices {
	.disclaimer-active .disclaimer-widget {
		position: absolute;
	}
}
@media @only-small-devices {
	.disclaimer-active .disclaimer-widget {
		position: absolute;
	}
}
