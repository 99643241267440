
ul.sitemap {
    margin: 0;
    padding: 0;
    list-style: none;
}

.sitemap-first-level {
    &:extend(.main-content h2 all);
}

.sitemap.content a {
    color: @black;

    &:hover,
    &:focus {
        color: @oebb-red;
    }
}