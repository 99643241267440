.md-fabasoft-form .group-start, .md-fabasoft-form .group-end, .md-fabasoft-form .form-horizontal .group {
    background: #f7f7f7;
    min-height: 8px;
    margin: 0 0;
    border-left: 1px solid #ededed;
    border-right: 1px solid #ededed;
    padding: 1px 20px;
}

.md-fabasoft-form .form-horizontal .group div * {
    /* padding-left:0;*/
}

.md-fabasoft-form .form-horizontal .group .fm-checkbox-inline {
    padding-left: 20px;
}

.md-fabasoft-form .group-start {
    border-top: 1px solid #ededed;
    margin-top: 0;

}

.md-fabasoft-form .group-end {
    border-bottom: 1px solid #ededed;
    margin-bottom: 24px;

}

.md-fabasoft-form .group-label {
    display: inline-block;
    font-size: 10px;
    margin-left: 20px;
}

.md-fabasoft-form .fm-required {
    font-size: 12px;
    color: #e2002a;
    vertical-align: top;
    line-height: 15px;
}

.md-fabasoft-form .fm-panel {
    padding-top: 10px;
    padding-bottom: 10px;
}

.md-fabasoft-form .fm-radio-inline span {
    margin-right: 12px;
    font-weight: normal;
}

.md-fabasoft-form .fm-radio span {
    font-weight: normal;
}

.md-fabasoft-form .fm-radio {
    display: block;
    font-weight: normal;
}

.md-fabasoft-form .fm-component input, .md-fabasoft-form .fm-component textarea, .md-fabasoft-form .fm-component select {
    font-family: "Frutiger Next Light";
    font-size: 16px;
    border: 1px solid #ccc;
    border-bottom-color: #e5e5e5;
    border-right-color: #e5e5e5;
    padding: 6px 12px;
}

.md-fabasoft-form .fm-component input:focus, .md-fabasoft-form .fm-component textarea:focus, .md-fabasoft-form .fm-component select:focus {
    outline: 0;
    -webkit-box-shadow: 0px 1px 10px 0px rgba(50, 50, 50, 0.75);
    -moz-box-shadow: 0px 1px 10px 0px rgba(50, 50, 50, 0.75);
    box-shadow: 0px 1px 10px 0px rgba(50, 50, 50, 0.75);
}

.md-fabasoft-form .form-control-feedback {
    z-index: 2;
    display: inline-block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    top: auto;
    right: auto;
    font-size: 18px;
    vertical-align: top;
    position: static;
}

.md-fabasoft-form .fm-checkbox-inline {
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    font-weight: normal;
}

.form-load-not-complete {
    visibility: hidden;
}

.md-fabasoft-form .fm-checkbox {
    display: block;
    width: 100%;
    padding-left: 0;
    position: relative;
}

.md-fabasoft-form .fm-checkbox-inline input {
    display: block;
    position: absolute;
    left: 0;
    top: 4px;
}

.md-fabasoft-form label.fm-checkbox, .md-fabasoft-form label.fm-checkbox-inline {
    font-weight: normal;
}

.md-fabasoft-form .fm-checkbox-inline * {
    display: inline-block;
    margin: 0;
    line-height: 1.4em;
}

.md-fabasoft-form .fm-checkbox-inline * {
    display: inline-block;
}

.md-fabasoft-form .fm-btn-panel {
    position: relative;
    text-align: right;
}

.md-fabasoft-form label {
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
    display: inline-block;
}

.md-fabasoft-form .fm-required-label {
    position: absolute;
    top: 8px;
    left: 15px;
    width: 130px;
    text-align: left;
}

.md-fabasoft-form .fm-required-label .ic-star {
    color: #e2002a;
}

.md-fabasoft-form .fm-btn-panel .ic-star {
    display: inline-block;
    position: absolute;
    top: -4px;
    left: -15px;
    font-size: 15px;
}

.md-fabasoft-form .fm-component-radio, .md-fabasoft-form .fm-component-checkbox {
    position: relative;
}

.md-fabasoft-form .fm-component-radio .form-control-feedback, .md-fabasoft-form .fm-component-checkbox .form-control-feedback {
    top: -10px;
    right: 0;
    display: inline-block;
    vertical-align: top;
    position: absolute;
}

.md-fabasoft-form .btn.btn-primary {
    font-family: "Frutiger Next Regular";
    display: inline-block;
    border: 0;
    color: #fff;
    background: #e2002a;
    padding: 7px 46px 8px;
    text-decoration: none;
    font-size: 16px;
    outline: 0;
}

.md-fabasoft-form .btn.btn-primary.btn-upload {
    padding: 3px 26px 4px;
    text-decoration: none;

    font-size: 15px;
}

.md-fabasoft-form .fm-alert-warn, .md-fabasoft-form .fm-alert-error, .md-fabasoft-form .fm-alert-success {
    border: 1px solid #bd352e;
    background: #f2d7d5;
    padding: 15px;
    margin-bottom: 20px;
}

.md-fabasoft-form .fm-btn-panel {
    margin-bottom: 20px;
}

.md-fabasoft-form .fm-btn-panel .btn:focus, .md-fabasoft-form .fm-btn-panel .btn:hover {
    border: 0;
    -webkit-box-shadow: 0px 1px 20px 0px rgba(50, 50, 50, 0.75);
    -moz-box-shadow: 0px 1px 20px 0px rgba(50, 50, 50, 0.75);
    box-shadow: 0px 1px 20px 0px rgba(50, 50, 50, 0.75);
}

.md-fabasoft-form .fm-btn-panel .btn:active {
    -webkit-box-shadow: inset 0px 1px 20px 0px rgba(50, 50, 50, 0.75);
    -moz-box-shadow: inset 0px 1px 20px 0px rgba(50, 50, 50, 0.75);
    box-shadow: inset 0px 1px 20px 0px rgba(50, 50, 50, 0.75);
}

.md-fabasoft-form .fm-panel {
    position: relative;
}

.md-fabasoft-form .form-control.width66, .width66 {
    width: 66%;
}

@media (max-width: 768px) {
    .md-fabasoft-form .form-control input[type="text"], .md-fabasoft-form .form-control textarea, .md-fabasoft-form .form-control select {
        width: 100%;
    }

    .md-fabasoft-form .form-control.width66, .width66 {
        width: 100%;
    }
}