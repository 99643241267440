
// Filter
.tablefilter-filter {
    @media @screen-md-max {
        display: none;
    }
    

    &--flex {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        &.cat-list-2 {
            @media @screen-lg-min {
                margin-top: 52px;
            }
        }
    }

    ul.tablefilter-filter__sublist {
        list-style: none;
        margin-left: 5px;
        margin-top: 10px;
    }
    
    li {
        //padding-right: 20px;
        position: relative;
    }

    .flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .col-md-6,
    .col-lg-6,
    .col-lg-5,
    .col-lg-7 {
        width: 100%;
        padding: 0;
    }

    @media @screen-md-min {
        .column-md-2 {
            column-count: 2;
        }
        .col-md-6 {
            width: ~'calc(100% / 2 - 15px)';
        }
    }

    @media @screen-lg-min {
        .col-lg-5 {
            width: ~'calc(100% / 12 * 5 - 20px)';
        }

        .col-lg-6 {
            width: ~'calc(100% / 12 * 6 - 20px)';
        }

        .col-lg-7 {
            width: ~'calc(100% / 12 * 7 - 20px)';
        }
    }
        
    
}

.tablefilter-filter__buttons {
    margin-top: 20px;


    @media @screen-md-max {
        position: sticky;
        background: white;
        padding: 5px 0;
        margin-bottom: 10px;
        margin-top: 0;
        top: 40px;
        transition: transform .55s ease;

        .scroll-down & {
            transform: translate(0,-100%);
        }
    }

    .md-button {
        margin: 0;
    }
    
}


// Overview
.tablefilter-list {
    table-layout: fixed;
    word-wrap: break-word;
  overflow-wrap: break-word;

-webkit-hyphens: auto;
   -moz-hyphens: auto;
        hyphens: auto;
}

.tablefilter-list__item[data-visible="false"] {
    display: none!important;
}

.tablefilter-list__item-50 {
    width: 50%;
}

.tablefilter-list__item-25 {
    width: 25%;
}



.hide-xs {
    @media @screen-xs-max {
        display: none!important;
    }
}

.tablefilter-filter__sublist {
    display: none;
}

.tablefilter-filter__sublist-toggler {
    background: transparent;
    border: none;
    color: @oebb-red;
    font-size: 28px;
    position: absolute;
    top: -5px;
    right: -5px;
    padding: 0;
}

.tablefilter-filter__txt {
    width: 100%;
}


.tablefilter-filter--s-suche {
    @media @screen-md-max {
        width: 100%;
    }
}

.width-min-30 {
    min-width: 30%;
}

.tablefilter-filter li {
    padding-left: 20px;

    &:first-child {
        margin-top: 0;
    }

    &.tablefilter-filter__parent {
        padding-right: 20px;
    }


    > input[type="checkbox"] {
        margin-left: -20px;
    }
}


.tablefilter__inner {
    position: relative;

    &.loading {
        &:before,
        &:after {
            display: flex;
            opacity: 1;
            transition: opacity 0s ease;
        }
    }

    &:before {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        pointer-events: none;
        font-family: 'oebb-fonticons';
        font-size: 100px;
        color: @black-60;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-weight: normal;
        content: "\e905";
        display: flex;
        justify-content: center;
        align-items: center;
        animation: spin 1.4s infinite linear;
        display: none;
    }

    &:after {
        content: "";
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 50;
        pointer-events: none;
        background: #fff; //rgba(255,255,255,.5);
        opacity: 0;
        transition: opacity .5s ease;

        @media @screen-md-max {
            top: 60px;
        }
    }
}

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}