
.stoerungsmeldungen {
    margin: 20px 0;

    .stoerungsmeldung {
        position: relative;
        margin-bottom: 15px;
        padding: 5px 5px 5px 35px;
        border: 1px solid @black-20;
    }


    .stoerungsmeldung__icon {
        font-size: 20px;
        position: absolute;
        top: 5px;
        left: 8px;
    }

    .stoerungsmeldung--success .stoerungsmeldung__icon { color: @success; }
    .stoerungsmeldung--warning .stoerungsmeldung__icon { color: @warning; }
    .stoerungsmeldung--error .stoerungsmeldung__icon { color: @error; }

    .stoerungsmeldung__title {
        margin: 0;
        font-size: 18px;
    }

    .stoerungsmeldung__link-title {
        color: currentColor;
        text-decoration: none;
        width: 100%;
        display: inline-block;

        &:hover,
        &:focus {
            color: @oebb-red;
        }
    }

    .stoerungsmeldung_arrow {
        float: right;
        transition: transform .4s ease;
    }

    .stoerungsmeldung--opened .stoerungsmeldung_arrow {
        transform: rotate(180deg);
    }

    .stoerungsmeldung__timeline {
        position: relative;
        padding-left: 0;
        list-style: none;
        display: none;
        font-size: 14px;

        &:before {
            content: "";
            width: 1px;
            height: ~'calc(100% - 2em)';
            transition: height .4s ease;
            background: @black-20;
            position: absolute;
            top: 0;
            left: 10px;
        }
    }

    .stoerungsmeldung__timeline-item {
        position: relative;
        margin-bottom: 20px;
        padding-left: 30px;

        &:last-child {
            margin-bottom: 0;
        }

        &:before {
            content: "";
            width: 10px;
            height: 10px;
            display: block;
            position: absolute;
            top: 0;
            left: 3px;
            background: @warning-background;
            border: 2px solid @warning;
            border-radius: 50%;
        }

        &--error:before { border-color: @error; background: @error-background; }
        &--success:before { border-color: @success; background: @success-background; }
    }

    .stoerungsmeldung__item-title {
        margin: 0;
    }

    p {
        margin: 0;
    }
}