

.off-canvas-open #off-canvas-menu {
	transform: translateX(0);
	visibility: visible;
}

.off-canvas-open #off-canvas-handler {
	z-index: 999999999;
	width: 100%;
	opacity: 0.2;
	background: black;
	left: @off-canvas-menu-width;
}

.off-canvas-open {
	overflow: hidden;

}
.off-canvas-open body {
	overflow: hidden;

}

#off-canvas-handler {
	z-index: 1;
	position: fixed;
	display: block;
	width: 70px;
	height: 100%;

	top: 0;
	left: 0;
	background: transparent;
	-webkit-transition: left .5s;
	-moz-transition: left .5s;
	transition: left .5s;

}


#off-canvas-link:focus, #off-canvas-link:hover {
	background: white;
	color: @oebb-red;
}

#off-canvas-link:active {
	background: @black-10;
	color: @oebb-red;

}

@media @large-devices-and-smaller {
	#off-canvas-menu {
		display: block;
	}
	#off-canvas-link {
		display: inline-block;
	}

	#off-canvas-handler {
		width: 25px;
	}
}
.oc {
	background: @black-55;
}

.oc-header {
	position:relative;
	background: @oebb-red;
	color:white;
	height:40px;
	overflow:hidden;
}

.oc-header h3 {
	margin:0;
	padding: 10px 50px 10px 16px;
	color: white;
	font-size: 18px;
}

/* Offcanvas schließen Button */
.oc-close-btn {
	display:inline-block;
	position: absolute;
	top:0;
	right:0;
	width:40px;
	height:40px;
	text-align:center;
	border: 1px solid @oebb-red;
	border-left: 1px solid white;

	background:@oebb-red;
	font-size: 18px;
	padding-top:5px;
	outline:0;
}

.oc-close-btn span:first-child{
	display:inline-block;
	position: absolute;
	top:50%;
	left:0;
	margin-top:-8px;
	right:0;
	width:40px;
	height:40px;
	border:0;

	text-align:center;
}
.oc-close-btn:active,.oc-close-btn:focus, .oc-close-btn:hover{
	border-left: 1px solid @oebb-red;
	background:white;
	color: @oebb-red;
}
.oc-close-btn:focus{
	outline:1px dotted @oebb-red;
	outline-offset: -4px;
}
.oc-close-btn:active{
	color: black;
}
/* Offcanvas eine ebene zurück Button */
.oc-back-btn{
	position:relative;
	display: block;
	width:100%;
	border:0;
	background:@black-5;
	border-bottom: 1px solid @black-20;
	padding: 0 0 0 40px;
	margin:0;
	text-align:left;
	outline:0;
	overflow:hidden
}

.oc-back-btn span:first-child{
	display:inline-block;
	position: absolute;
	top:50%;
	left:0;
	margin-top:-8px;
	right:0;
	width:40px;
	height:40px;
	border:0;
	color: @black-40;
	text-align:center;
}
.oc-back-btn span:last-child {

	display: inline-block;
	border-left:0;
	padding:10px 10px 10px 0;
	font-weight:bold;
}
.oc-back-btn:active,.oc-back-btn:focus, .oc-back-btn:hover{

	color: @oebb-red;
}
.oc-close-btn:active{
	color: black;
}
.oc-back-btn:active span:first-child,.oc-back-btn:focus span:first-child, .oc-back-btn:hover span:first-child{

	color: @oebb-red;
}

.oc-back-btn:focus{
	outline:1px dotted @oebb-red;
	outline-offset: -4px;
}
.oc-close-btn:active span:first-child{
	color: @oebb-red;
}


/* Offcanvas eine ebene zurück Button und Link zur übergeordeter Ebene */
.oc-back-panel{
	position: relative;
	min-height:40px;
	background:@black-5;
	border-bottom: 1px solid @black-20;
	padding: 0 0 0 0;
}


.oc-back-panel .oc-back-btn{
	position:absolute;
	top:0;
	left:0;
	width:39px;
	height:39px;
	border-bottom: 0;
	padding:0;
}
.oc-back-panel .oc-back-btn span:last-child{
	padding:0;
	font-weight:normal;
}
.oc-back-panel a {
	width:100%;
	display: block;
	padding: 0 0 0 40px;
	outline:0;
	color:black;
}
.oc-back-panel a span {
	display: inline-block;
	width:100%;
	border-left: 1px solid @black-20;
	padding: 10px 10px 10px 10px;
	font-weight:bold;
}
.oc-back-panel a:active,.oc-back-panel a:focus, .oc-back-panel a:hover{

	color: @oebb-red;
}
.oc-back-panel a:active{
	color: black;

}

.oc-back-panel a:focus span{
	outline:1px dotted @oebb-red;
	outline-offset: -4px;
}
.oc ul{
	list-style-type: none;
	margin: 0;
}
.oc ul ul{
	padding:0;
 }
/* Navigation  in Offcanvas*/

.oc-nav{
	position:relative;
	margin: 0;
	padding: 0;
 	width: 100%;
	padding:20px 0;
   	background: white;
	z-index: 10;
	left:0;
	-webkit-transition: left .5s;
	-moz-transition: left .5s;
	transition: left .5s;
	visibility: hidden;

	&.panel-visible,
	.off-canvas-open .panel-visible > & {
		visibility: visible;
	}
}
.oc-sub-panel{
	width: 100%;
	position:absolute;
	z-index: 10;
	left:@off-canvas-menu-width;
	-webkit-transition: left .5s;
	-moz-transition: left .5s;
	transition: left .5s;
	visibility: hidden;

	.off-canvas-open &.panel-visible {
		visibility: visible;
	}

}
.oc-nav li{
	border-bottom: 1px solid @black-20;
	display:block;
	position:relative;
	overflow:hidden;
}
.oc-nav li:last-child{
	border-bottom:0;
}
.oc-nav a{
	display:block;
	width:100%;
	color:black;
	padding: 0 40px 0 0;
	text-decoration: none;
	outline:0;
}
.oc-nav a.full{
	padding: 0 4px 0 0;
}
.oc-nav a span:first-child{
	display:inline-block;
	padding:10px 0 10px 10px;
	width:100%;

}

.oc-nav a span.ic-arrow2-right{
	display:inline-block;
	position:absolute;
	top:50%;
	margin-top: -8px;
	right:10px;
	padding:0;
	color: @black-55;
}
.oc a span span{
	width:auto!important;
	padding:0 0 0 2px!important;
	display:inline-block!important;
	border:0;
	/*position:absolute;
	top:50%;
	margin-top: -8px;
	right:40px;
	border:0;
	color: @black-55;*/
}

.oc-nav a:active,.oc-nav a:focus,.oc-nav a:hover, .oc-nav a:active span.ic-arrow2-right,.oc-nav a:focus span.ic-arrow2-right,.oc-nav a:hover span.ic-arrow2-right{

	color: @oebb-red;
}
.oc-nav a:active, .oc-nav a:active span.ic-arrow2-right{
	color: black;
}
.oc-nav li.current a,.oc-nav li.current a span{
	color: @oebb-red;
}


.oc-nav li div{
	position:relative;
}
.oc-nav a:focus span:first-child{
	outline:1px dotted @oebb-red;
	outline-offset: -4px;
}

.oc-nav li button{

	position:absolute;
	top:7px;
	right:0;
	width:39px;
	height:25px;
	border: 0;
	padding: 2px 0 0;
	background:white;
	color: @black-40;
   overflow:hidden;
}

.oc-nav li button:active,.oc-nav li button:focus,.oc-nav li button:hover{

	color: @oebb-red;
}
.oc-nav li button:active{
	color: black;
}

.oc-nav li button:focus{
	outline:1px dotted @oebb-red;
	outline-offset: -4px;
}


.oc-nav li.active > div > button, .oc-nav li.active > div > a{
	color:@oebb-red;
}
.oc-nav li ul>li:first-child{
	border-top: 1px solid @black-20;
}
.oc-nav li > ul > li > a{
	padding-left:20px;

}
.oc-nav li.active ul{
	background: @black-5;
}

.oc-nav ul li button{
	background: @black-5;
	color: @black-55;
}

.oc-nav-active-link {
	color: @oebb-red !important;
}

/*
	Links
*/
.oc-links{
	position:relative;
	z-index: 1;
margin:0 0;
	padding: 20px 0;
}

.oc-links li{
	padding: 8px 5px 8px 5px;
	display:block;
	position:relative;
	overflow:hidden;
}

.oc-links a{
	display:block;
	width:100%;
	text-decoration: none;
	outline:0;
	color: @black-10;
	padding:0 0 0 18px;
}
.oc-links a span{
	display:inline-block;
	position:absolute;
	left:4px;
	top:50%;
	margin-top:-8px;

}
.oc-links a:active,.oc-links a:focus,.oc-links a:hover {

	color: white;

}
.oc-links a:active{
	background: @black-70;
}

.oc-links a:focus{
	outline:1px dotted @white;
	outline-offset: -4px;
}

.oc-close-btn-accessibility {
	background: transparent;
	border: none;
	color: white;
	text-decoration: underline;
}


