.ntr-form-component {
	padding-bottom: 18px;
}

.ntr-form-component label {
	display: block;
	padding-bottom: 4px;
}

.ntr-form-component label:after {
	content: ' *';
	color: @oebb-red;
	font-weight: bold;
}

.ntr-form-component select {
	font-family: "Frutiger Next Light", "Source Sans Pro Light";
	font-size: 16px;
	border: 1px solid #ccc;
	border-bottom-color: #e5e5e5;
	border-right-color: #e5e5e5;
	padding: 6px 12px;
	width: 100%;
}