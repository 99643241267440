/* Acordeon-Element */

.content ul.accordeon,
.accordeonContent ul.accordeon {
	list-style-type: none;
	list-style: none;
	list-style-image:none;
	padding: 0;
	margin: 0;
	margin-bottom: 30px
}

.content ul.tabs ul.accordeon,
.accordeonContent ul.tabs ul.accordeon {
	list-style-type: none;
	list-style: none;
	list-style-image:none;
	padding: 0;
	margin: 0;
}

ul.accordeon > li {
	padding: 0;
	margin: 0;
	border-bottom: 1px solid @black-10;
}

ul.accordeon > li > a {
	padding: 10px 0;
	margin: 0;
	display: block;
	position: relative;
	width: 100%;
	padding-left: 24px;
	text-decoration: none;
	color: @dark-font;
	font-size: 16px;

}
ul.accordeon div:focus{
	outline: none;
}
ul.accordeon > li > a:focus, ul.accordeon > li > a:hover {
	color: @oebb-red;
}
ul.accordeon > li > a:focus{
	outline: 1px dotted #ccc;
}

ul.accordeon > li > a:active {
	color: black;

}

ul.accordeon > li > a > h3 {
	padding: 0;
	margin: 0;
	font-size: 16px;
	color: @oebb-red;
}

ul.accordeon > li > a span {
	display: block;
	position: absolute;
	top: 50%;
	margin-top: -11px;
	left: 0;
	color: @oebb-red;
	font-size: 20px;
}

ul.accordeon:not(.edit-mode) > li > div {
	display: none;
}

/*  Ge�ffnettes Accordeon-Element*/
ul.accordeon:not(.edit-mode) > li.open > div {
	display: block;
	margin-top: -8px;

}

/*  Minus-Icon wird angezeigt.*/
ul.accordeon > li.open > a span:before {
	content: "\e657";
}

// Listen-Icons in einem Akkordeon
.content ul.accordeon ul,
.accordeonContent ul.accordeon ul {
	list-style-image: url(@icon-name-bullet-point-primary);
}

.content ul.accordeon ul ul,
.accordeonContent ul.accordeon ul ul {
	list-style-image: url(@icon-name-bullet-point-secondary);
}

.content ul.accordeon ul ul ul,
.accordeonContent ul.accordeon ul ul ul {
	list-style-image: url("../../images/line-gray.svg");
}

// Listen-Icons in einem Akkordeon in einem Tab
.content ul.tabs ul.accordeon ul,
.accordeonContent ul.tabs ul.accordeon ul {
	list-style-image: url(@icon-name-bullet-point-primary);
}

.content ul.tabs ul.accordeon ul ul,
.accordeonContent ul.tabs ul.accordeon ul ul {
	list-style-image: url(@icon-name-bullet-point-secondary);
}

.content ul.tabs ul.accordeon ul ul ul,
.accordeonContent ul.tabs ul.accordeon ul ul ul {
	list-style-image: url("../../images/line-gray.svg");
}

.accordeon__toggle-btn {
	background: @oebb-red;
	border: 1px solid @oebb-red;
	padding: 10px;
	color: #fff;
	text-decoration: none;
	margin: 10px 0 -30px auto;
	display: block;
	min-width: 100px;
	z-index: 9;
	position: relative;

	&:hover,
	&:focus {
		background: #fff;
		color: @oebb-red;
	}
}

.accordeon__no-bullet-point {
	list-style: none;
}
