@import 'variables';

.animation-lottie {
    background-color: #fff;
    position: sticky;
    top: 83px;
    padding: 10px 0;
    z-index: 10;
}

@media @screen-md-max {
    .animation-lottie {
        top: 40px;
        height: auto;
    }
}
