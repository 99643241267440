.country-nav {
	.clearfix();
	position: absolute;
	right: @container-padding + 70; // + min-width of ".lang-dd-nav-current"
	top: 0;
	@media @only-medium-devices {
		right: @container-padding-medium-devices + 70; // + min-width of ".lang-dd-nav-current"
	}
	@media @only-small-devices {
		right: @container-padding-small-devices + 70; // + min-width of ".lang-dd-nav-current"
	}
	height: 40px;
}

.country-nav-list {
	display: none;
	position: absolute;
	width: auto;
	background: white;
	-webkit-box-shadow: 0 1px 10px -2px rgba(0, 0, 0, 0.61);
	-moz-box-shadow: 0 1px 10px -2px rgba(0, 0, 0, 0.61);
	box-shadow: 0 1px 10px -2px rgba(0, 0, 0, 0.61);
	top: 100%;
	right: 0;
	overflow: hidden;
	margin: 0 0 10px 0;
	padding: 6px;
	z-index: 2;
}

.country-nav-current {
	border-right: 1px solid white;
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	width: 55px;
	height: 40px;
	background: transparent;
	z-index: 3;
	padding-left: 8px;
	padding-right: 8px;
	padding-top: 6px;
}

.country-nav-current span {
	position: absolute;
	color: white;
	top: 12px;
	right: 3px;
}

.country-nav-current span.ic-sphere {
	right: 25px;
}

.country-nav-current img {
	border: 1px solid #666;
	margin-top: 2px;
}

.country-nav-current:active, .country-nav-current:focus, .country-nav-current:hover, .country-nav-current.open {
	background: white;
	color: @oebb-red;
}

.country-nav-current:active span, .country-nav-current:focus span, .country-nav-current:hover span, .country-nav-current.open span {

	color: @oebb-red;
}

.country-nav-list li {
	display: block;

}

.country-nav-list li a {
	display: inline-block;
	color: @dark-font;
	text-decoration: none;
	width: 100%;
	padding: 4px 8px 4px 4px;
	border-top: 1px solid transparent;
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
	border-bottom: 1px solid @black-20;
	white-space: nowrap;
	font-size: 16px;
	font-family: "Frutiger Next Light", "Source Sans Pro Light", Arial;
}

.country-nav-list li:last-child a {
	border-bottom: 1px solid transparent;
}

.country-nav-list li a img {
	display: inline-block;
	margin-right: 8px;
	width: 36px;
	height: 25px;
	border: 1px solid #666;
	vertical-align: middle;
}

.country-nav-list li a .ic-sphere {
	display: inline-block;
	margin-right: 5px;
	width: 36px;
	height: 25px;
	border: 1px solid transparent;
	vertical-align: middle;
	text-align: center;
	font-size: 18px;
}

.country-nav-list li a:focus, .country-nav-list li a:hover {
	border: 1px solid @black-70;

}