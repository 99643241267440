
.sharelinks-wrapper {
    margin: 40px 0 20px;
}

.content .sharelinks,
.sharelinks {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        margin: 0 15px 0 0;
    }

    .social-link {
        font-size: 0;
        text-decoration: none!important;

        &:before {
            font-size: 24px;
            line-height: 24px;
        }

        &--mail {color: @dark-font; &:before {font-size: 30px;} }
        &--facebook {color: #3B5998;}
        &--twitter {color: #55ACEE;}
        &--linkedin {color: #0077b5;}
        &--xing {color: #026466;}
        &--googleplus {color: #d34836;}

        &:hover,
        &:focus {
            opacity: .8;
        }
    }
}
