.login-area {
    background: @black-3;
    border: 1px solid #ededed;
    padding: 20px 20px 0;
    max-width: 760px;

    label {
        display: block;
        font-weight: bold;
        font-size: 16px;
        line-height: 1.75em;
    }

    input {
        width: 66%;
        font-size: 16px;
        border: 1px solid #ccc;
        border-bottom-color: #e5e5e5;
        border-right-color: #e5e5e5;
        padding: 6px 12px;
        background: white;

        &:focus {
            box-shadow: 0 1px 10px 0 rgba(50,50,50,0.75);
        }
    }

    &__field {
        margin-bottom: 20px;
    }
}