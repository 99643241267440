


.x-parallax {
	position: relative;
	overflow:hidden;
	max-height:500px;

}

.x-parallax-window {
	 width:100%;
	 background-attachment: scroll;
	 background-position-x: center;
	 background-size:100% auto;
	 background-position-y: 0;

 }

.x-parallax-animation{

	animation: animationFrames ease 2s;
	animation-iteration-count: 1;
	transform-origin: 50% 50%;
	animation-fill-mode:forwards; /*when the spec is finished*/
	-webkit-animation: animationFrames ease 1s;
	-webkit-animation-iteration-count: 1;
	-webkit-transform-origin: 50% 50%;
	-webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/
	-moz-animation: animationFrames ease 1s;
	-moz-animation-iteration-count: 1;
	-moz-transform-origin: 50% 50%;
	-moz-animation-fill-mode:forwards; /*FF 5+*/
	-o-animation: animationFrames ease 1s;
	-o-animation-iteration-count: 1;
	-o-transform-origin: 50% 50%;
	-o-animation-fill-mode:forwards; /*Not implemented yet*/
	-ms-animation: animationFrames ease 1s;
	-ms-animation-iteration-count: 1;
	-ms-transform-origin: 50% 50%;
	-ms-animation-fill-mode:forwards; /*IE 10+*/
	animation-delay: 0.8s;
}

@keyframes animationFrames{

	0% {
		opacity:0;
		transform:  translate(0px,-25px)  ;
	}
	100% {
		opacity:1;
		transform:  translate(0px,0px)  ;
	}
}

@-moz-keyframes animationFrames{
	0% {
		opacity:0;
		-moz-transform:  translate(0px,-25px)  ;
	}
	100% {
		opacity:1;
		-moz-transform:  translate(0px,0px)  ;
	}
}

@-webkit-keyframes animationFrames {
	0% {
		opacity:0;
		-webkit-transform:  translate(0px,-25px)  ;
	}
	100% {
		opacity:1;
		-webkit-transform:  translate(0px,0px)  ;
	}
}

@-o-keyframes animationFrames {
	0% {
		opacity:0;
		-o-transform:  translate(0px,-25px)  ;
	}
	100% {
		opacity:1;
		-o-transform:  translate(0px,0px)  ;
	}
}

@-ms-keyframes animationFrames {
	0% {
		opacity:0;
		-ms-transform:  translate(0px,-25px)  ;
	}
	100% {
		opacity:1;
		-ms-transform:  translate(0px,0px)  ;
	}
}




.parallax-container {
	position: relative;
	overflow: hidden;
	width:100%;
	height: 400px;
}

.parallax {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
}
.main-content .parallax img {
	display: none;
	position: absolute;
	left: 50%;
	bottom: 0;
    width: 100%;
	height: auto;
  //  min-width: 100%;
//	min-height: 100%;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

.parallax-content {
	position: absolute;
	width: 100%;
	top: 50%;
	text-align: center;
	margin-top:-75px;
	opacity:1;
}


.parallax-content h1 {
	color: @white;
	text-shadow: 1px 1px 1px #000;
	margin: 0.2em 0;
}

.parallax-content div.md-button a{
	margin: 0 0 20px 0;
	display: inline-block;
	background-color: @oebb-red;
	padding: @container-padding-small-devices;
	color: @white;
	text-decoration: none;
}

.parallax-content div.md-button a:hover, .parallax-content div.md-button a:active, .parallax-content div.md-button a:focus{
	color: @oebb-red;
	background-color: @white;
}

.parallax-content div.md-button a ~ .parallax-content div.md-button a{
	margin-top: -10px;
}

