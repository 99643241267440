
.teaser-image {
    position: relative;
}

.teaser-image__link {
    &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #000;
        opacity: 0;
        transition: opacity .25s ease;
    }

    &:hover {
        &:before {
            opacity: .6;
        }
    }
}

.teaser-image__img {
    display: block;
}

.teaser-image__text-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 20px;
    color: white;
    background: linear-gradient(to bottom,rgba(0,0,0,0) 55%,rgba(0,0,0,.2) 65%,rgba(0,0,0,.6) 100%) rgba(0,0,0,0)
}

.teaser-image .teaser-image__title {
    font-size: 28px;
    margin: 0;
}

.teaser-image .teaser-image__subtitle {
    font-size: 16px;
}