
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.8);
  z-index: 1001;

  &--closed {
      display: none!important;
  }
}

.overlay__header {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  padding: 10px;
  background: rgba(0,0,0,.8);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.overlay__title {
  color: white;
  margin: 0;
  font-size: 24px;

  @media @screen-lg-min {
    font-size: 32px;
  }
}

.overlay__buttons {
  margin-left: auto;
}

.overlay__button {
  color: #fff;
  background: transparent;
  border: none;
  margin-left: 10px;
  font-size: 18px;
  border: 1px solid transparent;
  padding: 1px 3px;
  text-decoration: none;

  @media @screen-lg-min {
    font-size: 24px;
    padding: 1px 6px;
  }

  &:hover,
  &:focus {
    color: @oebb-red;
    background: white;
    border-color: @oebb-red;
  }

  &[disabled] {
    opacity: .5;
  }

  &--close {
    font-size: 30px;
    line-height: 24px;
  }
}

.overlay__content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  display: flex;
  align-items: center;
}

.overlay__gallery-video {
  width: auto;
  height: auto;
  max-height: ~'calc(100% - 95px)';
  display: inline-block;
}

.overlay__gallery-img {
  max-width: 100%;
  max-height: ~'calc(100% - 95px)';
  width: auto;
  height: auto;
  display: inline-block;

  transition: transform .3s ease;

  &[data-scale="1"] {transform: scale3d(1, 1, 1)}
  &[data-scale="2"] {transform: scale3d(2, 2, 1)}
  &[data-scale="3"] {transform: scale3d(3, 3, 1)}
  &[data-scale="4"] {transform: scale3d(4, 4, 1)}
  &[data-scale="5"] {transform: scale3d(5, 5, 1)}
  &[data-scale="6"] {transform: scale3d(6, 6, 1)}
  &[data-scale="7"] {transform: scale3d(7, 7, 1)}
  &[data-scale="8"] {transform: scale3d(8, 8, 1)}
  &[data-scale="9"] {transform: scale3d(9, 9, 1)}
  &[data-scale="10"] {transform: scale3d(10, 10, 1)}
}

.overlay__img-caption {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  padding: 10px;
  background: rgba(0,0,0,.8);
  color: white;
}

.overlay__gallery-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  height: 100%;

  .overlay--opened & {
    transition: transform .35s ease;
  }
}

.overlay__gallery-list-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100vw;
  flex-shrink: 0;
}


.overlay__control {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 3;

  background: none;
  border: none;
  color: white;
  font-size: 35px;
  border: 1px solid transparent;
  background: #000;
  padding: 0;

  .ic-arrow2-left,
  .ic-arrow2-right {
    display: block;
  }

  @media @screen-lg-min {
    font-size: 100px;
    background: transparent;
  }

  &--forward {
    margin-left: auto;
    left: auto;
    right: 0;
  }

  &:focus,
  &:hover {
    color: @oebb-red;
    border-color: @oebb-red;
    background: white;
  }
}