.content {
	& ul.badges {
		display: flex;
		flex-direction: row;
		margin: 0;
		padding: 0;

		list-style: none;

		/** 55% fade is maximum for accessibility with dark font color */
	}
	ul.badges li.badges__badge, .single__badge {
		--oebb-badge-color-background: fade(#41464b, 40%);

		display: block;
		padding: 0.35em 0.75em;
		margin-right: 0.5em;

		background-color: var(--oebb-badge-color-background);
		border-radius: 1em;

		color: #2d3237;
		font-weight: bold;
		font-size: 0.875em;

		&--red {
			--oebb-badge-color-background: fade(#e2002a, 40%);
		}

		&--green {
			--oebb-badge-color-background: fade(#87af28, 40%);
		}

		&--gray {
			--oebb-badge-color-background: fade(#41464b, 40%);
		}

		// &--yellow {
		// 	--oebb-badge-color-background: fade(#ebc81e, 40%);
		// }

		&--orange {
			--oebb-badge-color-background: fade(#f89306, 40%);
		}

		&--blue {
			--oebb-badge-color-background: fade(#3d85d0, 40%);
		}
	}
}
