.piwik-frame{
	border: 0;
	height: 190px;
	margin-left:-8px;
	margin-right:-8px;
	margin-bottom:0;
	display:block;
	width: 100%;
}

#piwik-image{
	border:0;
}

@media screen and (max-width: 800px){
	.piwik-frame {
		height: 220px;
	}
}
@media screen and (max-width: 540px){
	.piwik-frame {
		height: 240px;
	}
}
@media screen and (max-width: 440px){
	.piwik-frame {
		height: 270px;
	}
}
@media screen and (max-width: 400px){
	.piwik-frame {
		height: 300px;
	}
}
@media screen and (max-width: 380px){
	.piwik-frame {
		height: 320px;
	}
}
@media screen and (max-width: 360px){
	.piwik-frame {
		height: 340px;
	}
}
@media screen and (max-width: 340px){
	.piwik-frame {
		height: 360px;
	}
}
@media screen and (max-width: 300px){
	.piwik-frame {
		height: 380px;
	}
}