/* Normale Tabelle */
.content table,
.container table {
	margin-bottom: 20px;
	margin-top: 20px;
	width: 100%;
	max-width: 100%;
	min-width: 300px;
	position: static;
	border-collapse: collapse;
	z-index: 100;
}

.vertical-scroll .content table {
	margin-bottom: 0;
	margin-top: 0;
}

.content table td,
.container table td {
	font-family: "Frutiger Next Regular", "Source Sans Pro Regular", Arial;
	padding: 12px;
	border-bottom: 1px solid @black-20;
	border-top: 1px solid @black-20;
	background: white;
	text-align: left;
	vertical-align: top;
	color: @black;

	&:not(:first-of-type) {
		border-left: 1px solid transparent;
	}

	&:not(:last-of-type) {
		border-right: 1px solid transparent;
	}
}

.content table p {
	margin: 0;
}

.content table caption,
.container table caption {
	font-family: "Frutiger Next Bold", "Source Sans Pro Bold", Arial, sans-serif;
	color: @black;
	font-size: 18px;
	text-align: left;
	margin: 15px 0;
}

.content table th,
.container table th {
	font-family: "Frutiger Next Regular", "Source Sans Pro Regular", Arial;
	min-width: 80px;
	max-width: 280px;
	padding: 12px;
	color: @black;
	border-bottom: 2px solid @black-20;
	border-top: 1px solid @black-20;
	background: @black-5;
	text-align: left;
	font-weight: bold;
	vertical-align: middle;
	position: relative;
}

// .container table th:nth-of-type(1) {
// 	white-space: nowrap;
// }

.container table tbody th:nth-of-type(2),
.container table td.thin-border:nth-of-type(2) {
	width: 80%;
}

/* Wenn th nicht in der 1. Zeile ist.*/
.content table tbody th,
.container table tbody th {
	border-bottom: 1px solid @black-20;
}

.content table.has-icon tbody th,
.container table.has-icon tbody th {
	position: relative;
}

.content table.has-icon tbody th,
.container table.has-icon tbody th {
	padding-right: 45px;
}

.content table.strong tbody td,
.container table.strong tbody td,
.content table tbody .strong,
.container table tbody .strong {
	font-weight: bold;
}

.content table.not-strong tbody td,
.container table.not-strong tbody td,
.content table tbody .not-strong,
.container table tbody .not-strong,
content table.strong tbody .not-strong,
.container table.strong tbody .not-strong {
	font-weight: normal;
}

.content table td.left,
.content table th.left,
.container table td.left,
.container table th.left {
	text-align: left;
}

.content table td.center,
.content table th.center,
.container table td.center,
.container table th.center {
	text-align: center;
}

.content table td.right,
.content table th.right,
.container table td.right,
.container table th.right {
	text-align: right;
}

.content table a,
.container table a {
	color: @oebb-red;
	text-decoration: none;
}

.content table a:hover, .content table a:active, .content table a:focus,
.container table a:hover, .container table a:active, .container table a:focus {
	color: @dark-red;
}

.container table span {
	color: @oebb-red;
}

.content table.lateral,
.container table.lateral {
	width: 560px;
}

.table-wrapper .event-column-date {
	white-space: nowrap;
}

/* DMS-Tabelle */
.md-dms table {
	font-size: 15px;
	border: 1px solid #cccccc;
}

.md-dms thead {
	border: 1px solid #cccccc;
}

.content .md-dms table th {
	padding-right: 18px;
	background: #F2F2F2 url(data:image/gif;base64,R0lGODlhFQAJAIABAAAAAAAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS4wLWMwNjAgNjEuMTM0Nzc3LCAyMDEwLzAyLzEyLTE3OjMyOjAwICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ4bXAuZGlkOjAxODAxMTc0MDcyMDY4MTE4OEM2REYyN0ExMDhBNDJFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjdCNTAyODcwMEY4NjExRTBBMzkyQzAyM0E1RDk3RDc3IiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjdCNTAyODZGMEY4NjExRTBBMzkyQzAyM0E1RDk3RDc3IiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDUzUgTWFjaW50b3NoIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MDE4MDExNzQwNzIwNjgxMTg4QzZERjI3QTEwOEE0MkUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MDE4MDExNzQwNzIwNjgxMTg4QzZERjI3QTEwOEE0MkUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4B//79/Pv6+fj39vX08/Lx8O/u7ezr6uno5+bl5OPi4eDf3t3c29rZ2NfW1dTT0tHQz87NzMvKycjHxsXEw8LBwL++vby7urm4t7a1tLOysbCvrq2sq6qpqKempaSjoqGgn56dnJuamZiXlpWUk5KRkI+OjYyLiomIh4aFhIOCgYB/fn18e3p5eHd2dXRzcnFwb25tbGtqaWhnZmVkY2JhYF9eXVxbWllYV1ZVVFNSUVBPTk1MS0pJSEdGRURDQkFAPz49PDs6OTg3NjU0MzIxMC8uLSwrKikoJyYlJCMiISAfHh0cGxoZGBcWFRQTEhEQDw4NDAsKCQgHBgUEAwIBAAAh+QQBAAABACwAAAAAFQAJAAACF4yPgMsJ2mJ4VDKKrd4GVz5lYPeMiVUAADs=) right center no-repeat;
	cursor: pointer;
}

.content .md-dms table th {
	border-left: 1px solid #cccccc;
	border-right: 1px solid #cccccc;
}

.content .md-dms table th.tablesorter-headerAsc {
	background: #F2F2F2 url(data:image/gif;base64,R0lGODlhFQAEAIABAAAAAAAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS4wLWMwNjAgNjEuMTM0Nzc3LCAyMDEwLzAyLzEyLTE3OjMyOjAwICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ4bXAuZGlkOjAzODAxMTc0MDcyMDY4MTE4OEM2REYyN0ExMDhBNDJFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjdCNTAyODc0MEY4NjExRTBBMzkyQzAyM0E1RDk3RDc3IiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjdCNTAyODczMEY4NjExRTBBMzkyQzAyM0E1RDk3RDc3IiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDUzUgTWFjaW50b3NoIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MDM4MDExNzQwNzIwNjgxMTg4QzZERjI3QTEwOEE0MkUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MDM4MDExNzQwNzIwNjgxMTg4QzZERjI3QTEwOEE0MkUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4B//79/Pv6+fj39vX08/Lx8O/u7ezr6uno5+bl5OPi4eDf3t3c29rZ2NfW1dTT0tHQz87NzMvKycjHxsXEw8LBwL++vby7urm4t7a1tLOysbCvrq2sq6qpqKempaSjoqGgn56dnJuamZiXlpWUk5KRkI+OjYyLiomIh4aFhIOCgYB/fn18e3p5eHd2dXRzcnFwb25tbGtqaWhnZmVkY2JhYF9eXVxbWllYV1ZVVFNSUVBPTk1MS0pJSEdGRURDQkFAPz49PDs6OTg3NjU0MzIxMC8uLSwrKikoJyYlJCMiISAfHh0cGxoZGBcWFRQTEhEQDw4NDAsKCQgHBgUEAwIBAAAh+QQBAAABACwAAAAAFQAEAAACDYwfoAvoz9qbZ9FrJC0AOw==) right center no-repeat;

}

.content .md-dms table th.tablesorter-headerDesc {
	background: #F2F2F2 url(data:image/gif;base64,R0lGODlhFQAEAIABAAAAAAAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS4wLWMwNjAgNjEuMTM0Nzc3LCAyMDEwLzAyLzEyLTE3OjMyOjAwICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4OEM2REYyN0ExMDhBNDJFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjhFNzNGQjI3MEY4NjExRTBBMzkyQzAyM0E1RDk3RDc3IiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjhFNzNGQjI2MEY4NjExRTBBMzkyQzAyM0E1RDk3RDc3IiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDUzUgTWFjaW50b3NoIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MDI4MDExNzQwNzIwNjgxMTg4QzZERjI3QTEwOEE0MkUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MDI4MDExNzQwNzIwNjgxMTg4QzZERjI3QTEwOEE0MkUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4B//79/Pv6+fj39vX08/Lx8O/u7ezr6uno5+bl5OPi4eDf3t3c29rZ2NfW1dTT0tHQz87NzMvKycjHxsXEw8LBwL++vby7urm4t7a1tLOysbCvrq2sq6qpqKempaSjoqGgn56dnJuamZiXlpWUk5KRkI+OjYyLiomIh4aFhIOCgYB/fn18e3p5eHd2dXRzcnFwb25tbGtqaWhnZmVkY2JhYF9eXVxbWllYV1ZVVFNSUVBPTk1MS0pJSEdGRURDQkFAPz49PDs6OTg3NjU0MzIxMC8uLSwrKikoJyYlJCMiISAfHh0cGxoZGBcWFRQTEhEQDw4NDAsKCQgHBgUEAwIBAAAh+QQBAAABACwAAAAAFQAEAAACDYyPAcmtsJyDVDKKWQEAOw==) right center no-repeat;
}

tr:hover td {
	background: @black-5;
}

td.md-dms-type {
	text-transform: uppercase;
}



td.md-dms-name a {
	display: block;
	position: relative;
	color: @black;
	width: 100%;
	padding-left: 24px;
	padding-right: 48px;
}

td.md-dms-name a:active, td.md-dms-name a:focus, .md-dms-name a:hover {
	color: @oebb-red;
	text-decoration: underline;
}

.container table span.ic-info {
	color: @oebb-grey;
}

.container table span.neutral {
	color: inherit;
}

td.md-dms-name a span {
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
}

td.md-dms-name .md-dms-name-new {
	left: auto;
	right: 0;
	background: #ebc81e;
	padding: 0 6px;
	color: @black;
	text-transform: uppercase;
}

.md-paging {
	text-align: center;
}

.md-paging span.md-paging-text {
	display: inline-block;
	padding-right: 4px;
	padding-left: 4px;
}

.md-dms input[type="text"] {
	width: 100%;
}

.md-dms label {
	display: block;
	font-weight: bold;
	color: #737373;
	margin-bottom: 4px;
	margin-top: 4px;
}

.content .md-dms table th.header.download-header {
	background: @black-5;
	cursor: initial;
	text-align: center;
}

.content table td.download-checkbox {
	text-align: center;
}

form[name="zipDownload"] input[type="submit"] {
	margin-bottom: 10px;
}

.md-dms-download-form-validation-message {
	padding: 12px;
	background-color: @error-background;
}

.md-dms fieldset {
	margin: 0;
	padding: 0;
	border: 0;
}

.md-dms table label {
	margin: 0;
}

.md-dms table th label {
	display: inline-block;
	color: @black;
}

table.width-50-50 tbody td {
	width: 50%;
}

table.width-25-75 tbody tr > * {
	width: 76%;
}

table.width-25-75 tbody tr > *:first-child {
	width: 24%;
}

.col-2 table {
	min-width: 0;
}

.multi-tables {
	column-count: 2;
	margin-top: 20px;
	margin-bottom: 20px;

	ul.accordeon:not(.edit-mode) > li.open > & {
		margin-top: 20px;
	}
}

.multi-tables table {
	-webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
	          page-break-inside: avoid; /* Firefox */
	               break-inside: avoid; /* IE 10+ */
}

.multi-tables table:first-child {
	margin-top: 0;
}

@media @screen-md-max {
	.multi-tables {
		column-count: 1;
	}
}

.table-wrapper {
	overflow-x: auto;
}

.sticky-column {
	position: sticky;
	left: 0;
}

.container table.admin-table td,
.container table.admin-table th {
	padding: 6px;
}


.sortable-column {
	&:hover,
	&:focus {
		color: @oebb-red;
		cursor: pointer;
	}
}

.container table span.sortable-column-icon,
.sortable-column-icon {
	padding-right: 5px;
	color: currentColor;
}

.content table table,
.container table table,
.container table.width-25-75 table {
	td {
		width: auto;
		padding: 4px;

		border: none;
	}
}

