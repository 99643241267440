footer {
    min-height: 200px;
    background: @black-55;
    color: white;
    position: relative;
    left: 0;
    -webkit-transition: left 0.5s, top 0.5s;
    -moz-transition: left 0.5s, top 0.5s;
    transition: left 0.5s, top 0.5s;

    .footer--white {
        background: white;
    }

    &.footer--small {
        min-height: 0;
    }

    &.footer--transparent {
        background: transparent;
    }

    .footer--logistics {
        padding-top: 5px;
        border-top: 1px solid @oebb-red;

        ul {
            margin: 0 0 10px;
        }

        a {
            color: @oebb-red;
            text-decoration: underline;
        }
    }
}

.footer__title {
    font-size: 24px;
    color: white;
    font-family: 'Frutiger Next Light', 'Source Sans Pro Light', Arial,
        sans-serif;
    font-weight: bold;
    margin: 55px 0 10px 0;
    display: block;
}

.footer__link-list li a {
    display: inline-block;
    position: relative;
    color: @black-2;
    text-decoration: none;
    width: 100%;
    padding: 8px 8px 8px 0;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid @black-40;
    white-space: nowrap;
    font-size: 16px;
    font-family: 'Frutiger Next Light', 'Source Sans Pro Light', Arial,
        sans-serif;
}
.off-canvas-open footer {
    left: @off-canvas-menu-width;
}
.footer__link-list li:last-child a {
    border-bottom: 1px solid transparent;
}

.footer__link-list li a:active,
.footer__link-list li a:focus,
.footer__link-list li a:hover {
    color: white;
    text-decoration: underline;
}

footer a span.ic-arrow2-right {
    display: inline-block;
    position: relative;
    top: 0;
    margin-left: -4px;

    width: 14px;
    overflow: hidden;
}

.site-nav > li ul li a:active {
    border: white;
    color: white;
}

footer ul {
    list-style: none;
    padding: 0;
    margin: 0 0 50px 0;
}

footer ul.no-header {
    margin-top: 94px;
}

.footer__link-list li {
    width: 100%;
    padding-right: 40px;
}

div.footer-social-items {
    padding-top: 34px;
}

.company-row {
    padding: 20px 0;
}

.company-image {
    display: inline-block;
    width: auto;
    max-width: 100%;
    max-height: 24px;
    margin-top: 10px;
}

@media @large-devices-and-smaller {
    .logo-row,
    .company-row {
        text-align: center;
    }

    .company-image {
        margin-top: 0;
    }
}

.logo-row {
    text-align: left;
    padding: 20px 0;
}

.logo-row ul {
    list-style-type: none;
    list-style: none;
    display: inline-block;
    margin: 0;
    padding: 0;
}

.logo-row li {
    display: inline-block;
    position: relative;
}

.logo-row li {
    padding-right: 20px;
}

.logo-row li a:focus,
.logo-row li a:hover {
    opacity: 0.7;
}
.logo-row li a:focus {
    outline: 1px dotted black;
}

.logo-row li a:active {
    opacity: 1;
}
.logo-row li a.footer-logo-wrapper {
    height: 45px;
    display: inline-block;
}

@media @large-devices-and-smaller {
    .logo-row,
    .logo-row ul {
        text-align: center;
    }

    .logo-row li {
        float: none;
    }
}

.awards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;
    background-color: #ffffff;
    padding: 0 10%;

    &__intro {
        font-family: 'Frutiger Next Light', 'Source Sans Pro Light', Arial,
        sans-serif;
        text-align: center;
        background-color: #ffffff;
        color: #000000;
        display: block;
    }

    &-img {
        display: inline-block;
        margin: 0.5rem;
        height: auto;
        max-height: 130px;
    }

    @media @large-devices-and-smaller {
        .awards-img {
            margin: 0.5rem;
            height: 100px;
        }
    }
}

.awards > div:first-child {
    flex: 1 0 100%;
  }

.awards p {
    font-size: 16px;
}