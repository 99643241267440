
.content {

    .preview-slider__preview {
        padding: 0 50px;
        position: relative;
    }

    .preview-slider__preview-inner {
        overflow: hidden;
    }

    .preview-slider__list {
        margin: 0 -5px;
        padding: 0;
        display: flex;
        flex-wrap: nowrap;

        transition: transform .35s ease;
    }

    .preview-slider__item {
        flex-shrink: 0;
        display: block;
        width: 20%;
        margin: 0;
        padding: 0 5px;

        img {
            margin: 0;
        }

        &--active .preview-slider__link {
            border: 3px solid @oebb-red;
            border-radius: 2px;
        }
    }

    .preview-slider__link {
        border: 3px solid transparent;
        border-radius: 2px;
        padding: 3px;
        display: block;
        background: transparent;
    }

    .preview-slider__control--backward,
    .preview-slider__control--forward  {
        position: absolute;
        top: 50%;
        display: block;
        width: 25px;
        height: 25px;
        border: none;
        
        background: @black;
        color: @white;
        transform: translateY(-50%);
        text-decoration: none;
        text-align: center;
        transition: opacity .15s ease;
        

        .ic-arrow1-right:before,
        .ic-arrow1-left:before {
            font-size: 18px;
            line-height: 25px;
        }
    }

    .preview-slider__control--backward {
        left: 0;
    }

    .preview-slider__control--forward {
        right: 0;
    }

    .preview-slider__control--inactive {
        pointer-events: none;
        opacity: .25;
    }

    .preview-slider__view {
        position: relative;
        width: 100%;
        margin-bottom: 30px;

        &[data-source="frame"] {
            height: 0;
            padding-top: 53%;
        }
    }

    .preview-slider__view iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}