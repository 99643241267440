.md-shipment-tracking {

    fieldset {
        margin: 0;
        padding: 0;

        border: none;
    }

    legend {
        margin-top: 1em;

        font-size: 1.75em;

        @media @screen-sm-max {
            font-size: 1.25em;
        }
    }

    p {
        font-size: 1.25em;

        @media @screen-sm-max {
            font-size: 1em;
        }
    }

    input[type="text"] {
        height: 2.5em;

        font-size: inherit;
    }

    input[type="submit"] {
        top: 0;
    }

}
