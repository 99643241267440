

.karten-legende a,
.row p a, .row .content ul:not(.teaser-slider-list):not(.tab-handler):not(.tab-handler-dropdown):not(.mashup-categories-menu):not(.mup-more-mashup-categories-menu):not(.no-list-icons) a:not(.md-button):not(.lSPrev):not(.lSNext), .row .content ol a, #disclaimer div.md-disclaimer-content a {
	color: @content-link-red;
	text-decoration: underline;
	display: inline-block;
	max-width: 100%;
	word-wrap: break-word; //e.g. youtube disclaimer links are not fitting on 320px screens
}

.row p a:hover, .row p a:active, .row p a:focus,
.row .content ul:not(.teaser-slider-list):not(.tab-handler):not(.tab-handler-dropdown):not(.mashup-categories-menu):not(.mup-more-mashup-categories-menu):not(.no-list-icons) a:not(.md-button):not(.lSPrev):not(.lSNext):hover,
.row .content ul:not(.teaser-slider-list):not(.tab-handler):not(.tab-handler-dropdown):not(.mashup-categories-menu):not(.mup-more-mashup-categories-menu):not(.no-list-icons) a:not(.md-button):not(.lSPrev):not(.lSNext):active,
.row .content ul:not(.teaser-slider-list):not(.tab-handler):not(.tab-handler-dropdown):not(.mashup-categories-menu):not(.mup-more-mashup-categories-menu):not(.no-list-icons) a:not(.md-button):not(.lSPrev):not(.lSNext):focus,
.row .content ol a:hover, .row .content ol a:active, .row .content ol a:focus,
#disclaimer div.md-disclaimer-content a:hover, #disclaimer div.md-disclaimer-content a:active, #disclaimer div.md-disclaimer-content a:focus {
	color: @dark-red;
}

.row p a:active, .row p a:focus, .row .content ul:not(.teaser-slider-list):not(.tab-handler):not(.tab-handler-dropdown):not(.mashup-categories-menu):not(.mup-more-mashup-categories-menu):not(.no-list-icons) a:not(.md-button):not(.lSPrev):not(.lSNext):focus, .row .content ol a:focus, #disclaimer div.md-disclaimer-content a:focus {
	text-decoration: underline;
}

.content h1, .content h2, .content h3, .content h4, .content p, .content .p, .content img {
	margin: 15px 0;
}

.content p + h1,
.content .p + h1,
.content p + h2,
.content .p + h2,
.content p + h3,
.content .p + h3,
.content p + h4,
.content .p + h4 {
	margin-top: 20px;
}

.content .md-infolinks-desc .md-infolinks-desc__title {
	font-size: 24px;
	font-weight: bold;
	font-family: @font-light;
	margin: 15px 0;
	padding: 0;
}

.content a.anchor-link {
	display: block;
	position: relative;
	top: -80px; // breadcrump 40px + menübar 40px
}

.content ul {
	list-style-image: url(@icon-name-bullet-point-primary);
}

.content ul ul {
	list-style-image: url(@icon-name-bullet-point-secondary);
}

.content ul ul ul {
	list-style-image: url("../../images/line-gray.svg");
}

.content ul li {
	margin-top: 10px;
	margin-bottom: 10px;
}

.content ul {
	margin-top: 10px;
	margin-bottom: 10px;
}

.content ol li {
	margin-top: 10px;
	margin-bottom: 10px;
}

.content ol {
	margin-top: 10px;
	margin-bottom: 10px;
}

.content ul.accordeon .md-infolinks ul, .content ul.accordeon .md-facts ul, .content ul.tabs .md-infolinks ul, .content ul.tabs .md-facts ul {
	list-style-type: none;
	list-style: none;
	list-style-image: none;
}

.content ul.accordeon .md-facts ul ul, .content ul.tabs .md-facts ul ul {
	list-style-image: url(@icon-name-bullet-point-secondary);
}

ul.link-icon,
.content ul.link-icon, .content ul.accordeon ul.link-icon, .content ul.tabs ul.accordeon ul.link-icon {
	list-style-type: none;
	list-style: none;
	list-style-image: none;
	padding-left: 0;
	margin-left: -5px;
	
}

.md-gray ul.link-icon,
.content .md-gray ul.link-icon, 
.content ul.accordeon ul.link-icon,
.content ul.tabs ul.accordeon ul.link-icon {
	font-weight: 700;	
}

dl {
	margin-top: 0;
	margin-bottom: 1em;
}

dt {
	font-weight: bold;
}

dd {
	margin-left: 1em;
	margin-bottom: 0.75em;
}

.dl-grid {
	display: grid;
	grid-template-columns: auto 1fr;
}

.content p {
	line-height: 1.4em;
}

// Content-Module
.md-headline-teaser {
	padding-bottom: 22px;
	margin-bottom: 30px;

	&:last-child {
		margin: 0; //WCMCW-959
		padding-bottom: 0; //WCMCW-964
	}
}

.md-headline-teaser h1,
.md-headline-teaser h2,
.md-headline-teaser h3,
.main-content .md-headline-teaser h1,
.main-content .md-headline-teaser h2,
.main-content .md-headline-teaser h3 {
	max-width: 670px;
	line-height: 38px;
	margin-bottom: 7px;
	hyphens: auto;
}

.md-headline-teaser p {
	max-width: 670px;
	margin-top: 7px;
	font-size: 22px;
}

.md-img-headline-teaser h1 {
	line-height: 38px;
	margin-bottom: 7px;
}

.md-img-headline-teaser p {
	margin-top: 7px;
	font-size: 22px;
}

.md-img-headline-teaser.new-to-rcg h1 {
	font-size: 52px;
	line-height: 55px;
	font-weight: bold;
	text-align: right;
	margin-top: 15px;
}

.md-img-headline-teaser.new-to-rcg h2 {
	font-family: "Frutiger Next Bold", "Source Sans Pro Bold", Arial;
	font-size: 44px;
	line-height: 47px;
	font-weight: bold;
	text-align: right;
	margin-top: 10px;
}

.md-img-headline-teaser.new-to-rcg p {
	margin-top: 20px;
	text-align: right;
}

.md-img-headline-teaser.new-to-rcg {
	margin-bottom: 36px;
}

.md-img-headline-teaser.new-to-rcg form {
	margin-top: 15px;
}

@media @medium-devices-and-smaller {
	.md-img-headline-teaser.new-to-rcg h1,
	.md-img-headline-teaser.new-to-rcg h2,
	.md-img-headline-teaser.new-to-rcg p {
		text-align: left;
	}
}

.md-text, .md-text-text, .md-text-teaser, .md-teaser-4, .md-teaser-2 {
	margin: 20px 0;
}

.md-text-text .md-gray img.portrait {
	display: block;
	float: right;
	position: relative;
	width: 100px;
	margin: 17px 0 0 10px;
}

.content .col-2 > .md-teaser {
	margin-left: -20px;
	margin-right: -20px;
	padding-left: 20px;
	padding-right: 20px;
}

.content .md-teaser h3 {
	margin-top: 5px;
	margin-bottom: 5px;
}

.content .md-teaser img {
	margin-top: 5px;
	margin-bottom: 5px;
}

.content .md-teaser p {
	margin-top: 5px;
	text-align: left;
	margin-bottom: 5px;
}

.content .md-teaser a {
	display: block;
	color: @black;
	text-decoration: none;
	padding-top: 12px;
	padding-bottom: 12px;
}

.content .md-teaser:hover {
	outline: 1px solid @black-10;
	outline-offset: -2px;
	color: @oebb-red;
	/*background: @black-5;*/
	border-radius: 5px;
}

.content .md-teaser .md-teaser-sublinks {
	margin: -10px 0 0 0;
	padding-bottom: 15px;
}

.content .md-teaser .md-teaser-sublinks p {
	margin: 0;
}

.content .md-teaser .md-teaser-sublinks a {
	margin: 0;
	padding-top: 0;
	padding-bottom: 0;
	color: @oebb-red;

}

.content .md-teaser .md-teaser-sublinks a:focus, .content .md-teaser .md-teaser-sublinks a:hover {
	color: @black;
}

.content .md-teaser-2 h3 {
	margin-top: 5px;
	margin-bottom: 5px;
}

.content .md-teaser-2 img {
	margin-top: 5px;
	margin-bottom: 5px;
}

.content .md-teaser-2 p {
	margin-top: 5px;
	text-align: left;
	margin-bottom: 5px;

}

.content .md-teaser-2 a {
	display: block;
	color: @black;
	text-decoration: none;
	padding-top: 12px;
	padding-bottom: 12px;
	-webkit-transition: all 1s ease-in-out;
	-moz-transition: all 1s ease-in-out;
	-o-transition: all 1s ease-in-out;
	transition: all 1s ease-in-out;
}

.content .md-teaser-2 .col-2:hover {
	outline: 1px solid @black-10;
	outline-offset: -2px;
	color: @oebb-red;
	/* background: @black-5;*/

}

.content .md-teaser-2 .md-teaser-sublinks {
	margin: -10px 0 15px 0;
}

.content .md-teaser-2 .md-teaser-sublinks p {
	margin: 0;
}

.content .md-teaser-2 .md-teaser-sublinks a {
	margin: 0;
	padding-top: 0;
	padding-bottom: 0;
	color: @oebb-red;

}

.content .md-teaser-2 .md-teaser-sublinks a:focus, .content .md-teaser-2 .md-teaser-sublinks a:hover {
	color: @black;
}

.content .md-teaser-4 img,
.content .md-teaser-4 .video-container {
	margin-bottom: 0;
}


.content .md-teaser-4 .md-teaser-4__content {
	margin: 4px 0 15px;
	text-align: center;
	line-height: 1.4em;
	
	p {
		margin: 0;
	}
}

.content .md-teaser-4 a, .content .md-img-text-teaser a {
	display: block;
	color: @black;
	text-decoration: none;
}

.md-img-text-teaser .md-img-text-teaser__title {
	font-size: 24px;
	font-family: @font-light;
	font-weight: bold;
	margin: 15px 0;
	hyphens: auto;
}



.content .md-teaser-4 .col-1 a {
	margin: 0 -20px -15px;
	padding: 0 20px 15px;
	text-decoration: none;
}

@media @only-small-devices {
	.content .md-teaser-4 .col-1 a {
		margin: 0;
		padding: 0;
	}
}

.content .md-img-text-teaser a:hover .row, .content .md-img-text-teaser a:focus .row {
	outline: 1px solid @black-10;
	outline-offset: -2px;
	color: @oebb-red;
	/* background: @black-5;*/
	border-radius: 5px;
}

.content .md-teaser-4 .col-1 a:focus, .content .md-teaser-4 .col-1 a:hover {
	color: @oebb-red;
	outline: 1px solid @black-10;
	outline-offset: -2px;
	border-radius: 5px;
}

.content .md-teaser-4 a.md-teaser-4__buchungs-link {
	padding: 10px 20px;
}

.content .md-teaser-4 .col-1 a:active, .content .md-img-text-teaser a:active .row {
	color: @black;
	text-decoration: none;
}

.md-text-img, .md-img-text, .md-img-text-teaser, .md-img-headline-teaser {
	margin: 10px 0;
}

.teaser-panel {

	margin: -15px auto 15px auto;
	width: 100%;
	max-width: 1200px;
	overflow: hidden;
	position: relative;
	z-index: 1;
	padding-top: 15px;
	padding-left: 20px;
	padding-right: 20px;
	background: #f2f2f2;

}

@media @medium-devices-and-smaller {
	.teaser-panel {
		padding-left: 40px;
		padding-right: 40px;
	}

	.teaser-panel .md-teaser-4 .col-1 + .col-1 + .col-1 {
		clear: left;

	}

	.teaser-panel .md-teaser-4 .col-1 + .col-1 + .col-1 + .col-1 {
		clear: none;

	}
}

@media @only-small-devices {
	.teaser-panel {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.teaser-panel + .teaser-panel {
	margin-top: -30px;
	padding-top: 2px;
	z-index: 2;
}

.teaser-panel .md-teaser-4 {
	margin-top: 0;
	/*margin-left: -15px;
	margin-right: -15px;*/

	margin-left: auto;
	margin-right: auto;
	max-width: 860px;
}

.teaser-panel .md-teaser-4 .col-1 a {
	margin: 0 -15px 0 -15px;
	text-decoration: none;
	padding: 5px 15px;
}

.teaser-panel .md-teaser-4 a, .teaser-panel .md-img-text-teaser a {
	display: block;
	color: #000000;
	text-decoration: none;
	padding: 5px 15px;
}

.teaser-panel .md-teaser-4 .col-1 a:hover {
	color: @oebb-red;
	outline: 1px solid #fff;
	outline-offset: -3px;

}

.teaser-panel .md-teaser-4 .col-1 {
	padding-left: 15px;
	padding-right: 15px;
}

.teaser-panel .md-teaser-4 .col-1 p {
	margin-bottom: 0;
}

.teaser-panel .md-teaser-4 .col-1 h3 {
	margin-bottom: 5px;
	margin-top: 0;
}

.md-gray {
	margin: 20px 0;
	padding: 1px 20px;
	background: @black-4;
}

.md-white {
	margin: 20px 0;
	padding: 1px 20px;
	background: @white;
}

h1 ~ .row,
h2 ~ .row,
h3 ~ .row {
	margin-top: -15px;

	& > div > ul {
		margin-top: 35px;
	}
}

h1,
h2,
h3 {
	& + .md-white,
	& + .md-gray {
		margin-top: -10px;
	}
}

.columns .row > div > ul {
	margin-top: 15px;
}

h2.hidden ~ .row {
	margin-top: 0;
}

/*
.md-text > .md-gray,.md-text-text > .md-gray{
 margin: 30px 0;
}*/

.md-facts ul {
	margin: 20px 0;
	padding: 0;
	list-style-type: none;
	list-style: none;
}

.md-facts ul li {
	display: block;
	padding-bottom: 8px;
	margin-bottom: 8px;
	border-bottom: 1px solid @black-10;
}

.md-facts ul li:last-child {
	padding-bottom: 0;
	margin-bottom: 0;
	border-bottom: 0;
}

.md-facts ul li:after {
	clear: both;
}

.md-facts ul li img {
	width: 20%;
	min-width: 80px;
	float: left;
	margin: 0;
	padding-right: 20px;
}

.main-content .md-facts ul ul {
	margin: 0 0 0 20px;
	list-style-image: url(@icon-name-bullet-point-secondary);
}

.md-facts ul ul li {
	display: list-item;
	padding-bottom: 0;
	margin: 0;
	border-bottom: 0;
}

.md-facts p {
	margin: 0;
}

.md-infolinks ul {
	margin: 20px 0;
	padding: 0;
	list-style-type: none;
	list-style: none;
}

.md-infolinks ul li {
	padding-bottom: 2px;
	margin-bottom: 2px;

}

.md-infolinks ul li:last-child {
	padding-bottom: 0;
	margin-bottom: 0;

}

.md-infolinks ul li a {
	display: inline-block;
	clear: left;
	color: @content-link-red;
	text-decoration: none;
	font-weight: bold;
}

.md-infolinks ul li a:hover, .md-infolinks ul li a:focus {
	text-decoration: none;
	color: @black;
}

.md-infolinks ul li a:active {
	color: @black-55;

}

.md-button,
a.md-button,
p a.md-button,
table a.md-button {
	margin: 0 0 20px 0;
	display: inline-block;
	background-color: @oebb-red;
	border: 1px solid @oebb-red;
	padding: @container-padding-small-devices;
	color: @white;
	font-family: @font-default;
	text-decoration: none;

	&.md-button--disabled {
		background-color: @black-60;
		border-color: @black-60;
		pointer-events: none;

		&:hover,
		&:focus,
		&:active {
			background-color: @black-60;
			border-color: @black-60;
			color: white;
		}
	}
}

oebb-fm-form .mag-corporate .fm-button-panel button[type=submit]:hover,
oebb-fm-form .mag-corporate .fm-button-panel input[type=submit]:hover,
oebb-fm-upload .upload-button:focus, oebb-fm-upload .upload-button:active, oebb-fm-upload .upload-button:hover,
.md-button:hover, .md-button:active, .md-button:focus,
a.md-button:hover, a.md-button:active, a.md-button:focus,
p a.md-button:hover, p a.md-button:active, p a.md-button:focus,
table a.md-button:hover, table a.md-button:active, table a.md-button:focus {
	color: @oebb-red;
	background-color: @white;
	opacity: 1;
}

.md-button:focus,
a.md-button:focus {
	outline: 1px dotted white;
	outline-offset: -4px;
}

a.md-button span {
	line-height: 1.125em;
	margin-right: 5px;
}

a.md-button ~ a.md-button,
p a.md-button ~ p a.md-button {
	margin-top: -10px;
}

.md-button-secondary {
	margin: 0 0 20px 0;
	display: inline-block;

	background-color: @white;
	border: 1px solid @oebb-red;
	padding: @container-padding-small-devices;
	color: @oebb-red;;
	font-family: @font-default;
	text-decoration: none;

	&.md-button--disabled {
		background: white;
		color: @black-70;
		border-color: @black-60;
		cursor: not-allowed;

		&:hover,
		&:focus,
		&:active {
			background: white;
			color: @black-70;
			border-color: @black-60;
		}
	}
}

.md-button-secondary:hover, .md-button-secondary:active, .md-button-secondary:focus {
	color: @white;
	background-color: @oebb-red;
}

.md-button--link {
	margin: 0 0 20px 0;
	display: inline-block;
	background-color: transparent;
	color: @oebb-red;;
	text-decoration: underline;
	border: none;
	padding: 0;
}

.button-blank {
	display: inline-block;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	vertical-align: middle;

	background: none;
	border: none;
}

.bahnhof .content oebb-popover {
	position: absolute;
	z-index: 10;

	transform: translateY(-10px);
}

.bahnhof .content oebb-popover-overlay {
	& > div {
		padding: 0.5em;

		font-weight: normal;

		& > p {
			margin-top: 0;
			margin-bottom: 0.5em;
		}

		& > dl {
			margin-bottom: 0;

			& > dd:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.bahnhof .bahnhof__accessibility {
	margin-top: 8px;
	p {
		line-height: normal;
	}

	span {
		color: initial;
	}
}

// .md-button--link:hover, .md-button--link:active, .md-button--link:focus {
// //	color: @white;
// //	background-color: @oebb-red;
// }

.contact-button.md-button {
	display: none;
}

@media @only-small-devices {
	.contact-button.md-button {
		display: block;
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		margin: 0;
		z-index: 9;
		text-align: center;
		height: 60px;
		font-size: 35px;
	}

	.contact-button-active footer {
		padding-bottom: 60px;
	}
}


.main-content .md-img-orig-width img {
	width: auto !important;
}

@media @only-small-devices {
	.main-content .md-img-orig-width-small-devices img {
		width: auto !important;
		max-width: 767px !important;
	}
}

@media @medium-devices-and-smaller {
	.main-content .md-img-orig-width-medium-devices img {
		width: auto !important;
		max-width: 991px !important;
	}
}

@media @large-devices-and-smaller {
	.main-content .md-img-orig-width-large-devices img {
		width: auto !important;
		max-width: 1199px !important;
	}
}

ul.karten-legende {
	list-style-type: none;
	list-style: none;
	margin: 0;
	padding: 0;
}

ul.karten-legende li {
	display: block;
	position: relative;
	border-bottom: 1px solid #e5e5e5;
	margin: 0 0 0 36px;
	padding: 5px 0;
}

ul.karten-legende li > span {
	display: block;
	position: absolute;
	top: 6px;
	left: -36px;
	width: 26px;
	height: 26px;
	line-height: 22px;
	text-align: center;
	margin-top: 0;
	border: 2px solid @oebb-red;
	border-radius: 50%;
	font-size: 15px;
	background: white;
	z-index: 2;
}

ul.karten-legende li > span.line {
	width: 2px;
	border: 0;
	left: -24px;
	height: 120%;
	background: #ccc;
	top: 0;

	line-height: normal;
	border-radius: 0;
	z-index: 1;
}

ul.karten-legende li:last-child span.line {
	background: transparent;
}

ul.karten-legende li:first-child span.line {
	height: 110%;
	top: 20px;
}

ul.karten-legende li:last-child {
	border-bottom-color: transparent;
}

ul.karten-legende a {
	text-transform: uppercase;
	font-family: "Frutiger Next Bold", "Source Sans Pro Bold", Arial, san-serif;
}

ul.karten-legende .karten-legende__subtext {
	display: block;
	text-transform: none;
	font-family: "Frutiger Next Regular", "Source Sans Pro Regular", Arial, san-serif;
}

.md-dms > .row > .col-2 button {
	background: transparent;
	border: none;
	position: relative;
	float: right;
	font-size: 25px;
}

@media @medium-devices-and-smaller {
	.md-dms > .row > .col-2 button {
		display: none;
	}
}

ul + .md-dms,
p + .md-dms {
	margin-top: 20px;
}

.md-dms h3 {
	position: relative;
	padding-right: 40px;
}

.md-dms h3 button {
	position: absolute;
	top: 1px;
	right: 1px;
	background: transparent;
	border: none;
}

@media @medium-devices-and-smaller {
	.md-dms h3 button {
		display: none;
	}
}

.dms-fullscreen-col-1 {
	display: none;
}

.dms-fullscreen-col-3 {
	width: 100%;
}
.md-iframe {
	margin-top: 20px;

	iframe {
		max-width: 100%;
	}

	&.ratio-9-16 {
		width: 100%;
		height: 0;
		padding-top: ~'calc(100% / 1920 * 730)';
		position: relative;

		.lazy-frame-placeholder {
			position: absolute;
			top: 0;
			left: 0;
		}

		iframe {
			width: 100%!important;
			height: 100%!important;
			position: absolute;
			top: 0;
			left: 0;
		}

		@media @only-small-devices {
			padding-top: ~'calc(100% / 320 * 375)';
		}
	}

	&.md-iframe--with-placeholder {
		iframe {
			visibility: hidden;
		}

		&.md-iframe--loaded {
			iframe {
				visibility: visible;
			}

			.lazy-frame-placeholder {
				display: none;
			}
		}
	}

	&.md-iframe--hidden {
		display: none;
	}
}

.md-iframe iframe {
	width: 100%;
	height: 450px;

	.col-2 > & {
		height: 425px;
		width: 425px;
	}

	&.giscard--xs,
	&.giscard--md {
		display: none;
	}

	@media @medium-devices-and-smaller {
		&.giscard--md {
			display: block;
		}

		&.giscard--hide-md {
			display: none;
		}
	}

	@media @only-small-devices {
		&.giscard--xs {
			display: block;
		}

		&.giscard--hide-xs {
			display: none;
		}
	}


}

.md-text > .row > .col-2 > .md-iframe iframe {
	width: 100%;
	height: 425px;
}

a.mce-item-anchor {
	display: block;
	position: relative;
	top: -100px !important;
	visibility: hidden;
}

@media @medium-devices-and-smaller {
	a.mce-item-anchor {
		top: -50px !important;
	}
}

.flex {
	display: flex;

	> li {
		margin-right: 10px;
	}
}

.flex-container {
	display: flex;
}

.flex-center {
	justify-self: center;
	align-self: center;
	text-align: center;
}

.flex-vertical {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.flex-align-cennter {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.wrapper--flip-order {
	@media @screen-md-max {
		display: flex;
		flex-direction: column;

		> div:first-child {
			order: 1;
		}

		.page-nav {
			display: none;
		}
	}
}

.flex--space-between {
	justify-content: space-between;
}

.flex--align-self {
	align-self: flex-start;
}

.flex--wrap {
	flex-wrap: wrap;
}

.flex-cl {
	width: 100%;
}

@media @screen-sm-min {
	.flex-cl--sm-50 {
		width: ~'calc(50% - 6px)';
	}
}

@media @screen-md-min {
	.flex-cl--md-25 {
		width: ~'calc(25% - 6px)';
	}

	.flex-cl--md-50 {
		width: ~'calc(50% - 6px)';
	}
}

@media @screen-lg-min {
	.flex-cl--lg-25 {
		width: ~'calc(25% - 6px)';
	}

	.flex-cl--lg-50 {
		width: ~'calc(50% - 6px)';
	}
}

.loading-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 200px;
}

.before-after-slider-image {
	img {
		display: block;
		width: unset;
		max-width: 100%;
		margin: 0 auto;
	}
}
