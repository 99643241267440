.md-image-map {

    &__map {
        position: relative;

        display: inline-block;
        margin: 0;
        padding: 0;
    }

    .md-image-map__image {
        display: block;

        width: 100%;
        max-width: 100%;

        @media @screen-sm-max {
            display: none;
        }
    }

    &__areas {
        margin: 0;
        padding: 0;

        list-style: none;

        @media @screen-sm-max {
            list-style: disc outside none;
        }
    }

    &__area {
        position: absolute;
        top: 0;
        left: 0;

        display: block;

        .edit-mode & {
            border: 1px solid aqua;
        }

        @media @screen-sm-max {
            position: static;
            top: auto;
            left: auto;

            width: auto !important; /** Overwrite inline styles */
            height: auto !important; /** Overwrite inline styles */

            .edit-mode & {
                border: 0;
            }
        }
    }

    &__area-content {
        display: none;

        .no-js &,
        .edit-mode & {
            display: block;
        }
    }

    @media @screen-sm-max {
        figcaption {
            display: none;
        }
    }

}
