@import 'variables';

.main-content {
    .apartments {
        &-row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1.75rem 2.5rem;
            margin: 1rem 0 2rem;

            @media @medium-devices-and-smaller {
                grid-template-columns: 1fr;
            }

            h3 {
                margin: 0 0 1rem;
            }
        }

        &-table-wrapper {
            overflow-x: auto;

            @media print {
                width: fit-content;
            }
        }

        &-teaser {
            margin-top: 1.5rem;

            &-image {
                background: lightgrey;
                text-align: center;

                @media print {
                    background: transparent;
                }

                img {
                    display: block;

                    @media print {
                        max-width: 25%;
                    }
                }
            }

            &-address {
                font-size: 1.5rem;

                h1 {
                    margin: 0 0 0.75rem;
                }

                p {
                    margin: 0;
                }
            }
            .gallery-slider-content {
                max-height: 350px;
            }

            @media @only-small-devices {
                .gallery-slider-content {
                    max-height: 150px;
                }
            }
        }

        table {
            margin: 1rem 0 0;

            th:first-child {
                width: 14rem;
            }
        }

        oebbx-gallery-slider,
        oebbx-gallery-thumbs {
            ul {
                margin: 0;
                padding: 0;
            }

            .gallery-slider-image {
                box-shadow: none;
                width: unset;
                height: unset;
            }

            .gallery-slider-embedded .gallery-slider-caption {
                padding: 0.4rem;
                font-size: 0.9rem;
            }

            @media print {
                margin-right: -1.25rem;

                &-backlink {
                    display: none;
                }

                &-teaser {
                    grid-template-columns: 1fr;

                    &-address {
                        order: -1;
                    }
                }

                table {
                    min-width: unset;

                    th:first-child {
                        width: 12rem;
                        background: none;
                    }

                    td {
                        hyphens: auto;
                    }
                }

                .gallery-thumbs-items {
                    display: none;
                }
                
                .gallery-thumbs-link {
                    display: block;
                    break-before: auto;
                    break-inside: avoid;
                }
    
                .gallery-thumbs-item--print {
                    display: block;
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    break-before: auto;
                    break-after: auto;
                    break-inside: avoid;
                    margin: 0.5rem 0;
                }
    
                .gallery-slider-nav {
                    display: none;
                }
            }
        }

        .apartments-synopsis-heading,
        p.apartments-synopsis-heading {
            margin-bottom: 1rem;
            font-size: 1.25rem;
        }

        .apartments-synopsis-title,
        p.apartments-synopsis-title {
            margin-top: 1rem;
            font-size: 1.25rem;
        }

        .apartments-inquiry-link,
        a.md-button.apartments-inquiry-link {
            margin-top: 1rem;

            &--floating {
                position: sticky;
                bottom: 40px;
                z-index: 2;

                align-self: flex-end;
                order: 1;

                box-shadow: rgba(50, 50, 50, 0.5) 0 7px 20px 0;

                font-size: 1.5rem;

                @media print {
                    display: none;
                }
            }
        }

        .apartments-sticky-container {
            position: relative;

            display: flex;
            flex-direction: column;
        }

        .apartments-icon {
            content: '';
            display: inline-block;
            width: 25px;
            height: 25px;
            margin-right: 6px;
            vertical-align: middle;

            background-image: url('../../images/icons/wohnen/wohnen-sprite.png');
            background-position: 100px 0;
            background-repeat: no-repeat;
            background-attachment: scroll;
            background-size: 800%;

            @media print {
                display: none;
            }

            &--schoolsAndChildren {
                background-position-x: 0;
            }

            &--health {
                background-position-x: -25px;
            }

            &--publicTransitConnection {
                background-position-x: -75px;
            }

            &--restaurants {
                background-position-x: -100px;
            }

            &--shopping {
                background-position-x: -125px;
            }

            &--culture {
                background-position-x: -150px;
            }

            &--sportsFacilities {
                background-position-x: -175px;
            }
        }
        &-print-button {
            margin-top: 1rem;

            @media print {
                display: none;
            }
        }
    }
}