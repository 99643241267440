/* Footer-Row */
.footer-row {
	font-size: 14px;
	font-family: "Frutiger Next Light", "Source Sans Pro Light", Arial;

}

.footer-row .row div:last-child {
	text-align: right;

}

.footer-row .row div:first-child {
	font-weight: bold;
	margin-top: 4px;
	color: @dark-font;
}

.footer-row ul {
	list-style-type: none;
	list-style: none;
	display: inline-block;
	margin: 0;
	padding: 0;
}

.footer-row li {
	display: inline-block;
	position: relative;
	float: left;
}

.footer-row li > button,
.footer-row li > a {
	position: relative;
	display: inline-block;
	color: @dark-font;
	padding: 4px 6px 4px 6px;

	text-decoration: none;
	white-space: nowrap;
	font-size: 14px;
	font-family: "Frutiger Next Light", "Source Sans Pro Light", Arial;
	background: transparent;
	border: none;
}

.footer-row li:last-child > button,
.footer-row li:last-child > a {
	padding-right: 0;
}

.footer-row li ~ li > button:before,
.footer-row li ~ li > a:before {
	content: ' ';
	display: block;
	position: absolute;
	top: 6px;
	left: 0;
	width: 1px;
	background: @black-20;
	height: 14px;
	z-index: 6;
}

.footer-row li > button:focus,
.footer-row li > button:hover,
.footer-row li > a:focus,
.footer-row li > a:hover {
	color: @oebb-red;
}


