@import 'variables.less';

.text-spacing,
.text-spacing-all * {
	letter-spacing: 0.12em;
	word-spacing: 0.16em;
	line-height: 1.5;
}

.bold {
	font-weight: bold;
}

.hidden {
	position: absolute;
	display: block;
	top: -999999px;
	left: -999999px;
}
.v-hidden {
	visibility: hidden;
}
.vertical-scroll {
	display: block;
	width: 100%;
	margin-bottom: 15px;
	overflow-y: hidden;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}

.screen-reader-text {
	position: absolute;

	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;

	border: 0;
	clip-path: inset(50%);

	white-space: nowrap;
}

.screen-reader-text:active,
.screen-reader-text:focus {
	min-width: 200px;
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;

	clip-path: none;

	white-space: inherit;
}


@media @screen-sm-max {
	.screen-reader-text--sm-max {
		position: static;

		width: auto;
		height: auto;
		margin: 0;
		padding: 0;
		overflow: visible;

		clip-path: none;

		white-space: inherit;
	}
}

.hide-accessible {
	display: block;
	position: absolute!important;
	left: -9999em!important;
}

.hide-xs {
	@media @screen-xs-max {
        display: none!important;
    }
}

.hide-sm {
	@media @screen-sm-max {
        display: none!important;
    }
}

.hide-md {
	@media @screen-md-max {
        display: none!important;
    }
}

.hide-lg {
	@media @screen-lg-max {
        display: none!important;
    }
}

.w-75 {
	min-width: 75%!important;
}

.align-right {
	text-align: right;
}

.nowrap {
	white-space: nowrap;
}

.uppercase {
	text-transform: uppercase;
}

@keyframes spin {
    100% {
        transform:rotate(360deg);
    }
}

.content .vertical-scroll table {
	max-width:100%;
	min-width:300px;
 }

.clearfix {
	.clearfix()
}