

.site-nav__menu-item {
	width: 100%;
}

.sublevel__link {
	display: inline-block;
	color: @dark-font;
	text-decoration: none;
	width: 100%;
	padding: 8px 24px 8px 8px;
	border-top: 1px solid transparent;
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
	border-bottom: 1px solid @black-20;
	white-space: nowrap;
	font-size: 16px;
	font-family: "Frutiger Next Light", "Source Sans Pro Light", Arial, sans-serif;

	&:focus,
	&:hover {
		border: 1px solid @black-70;
	}

	&:active {
		border: 1px solid @black;
		color: @black;
	}

	.site-nav__menu-item:last-child > & {
		border-bottom: 1px solid transparent;

		&:focus,
		&:hover {
			border: 1px solid @black-70;
		}
	}
}

.sublevel__link .ic-lock {
	display: block;
	position: absolute;
	top: 50%;
	right: 8px;
	margin-top: -8px;
	width: 16px;
	text-align: right;
	opacity: 1.0;
}

.sublevel__parent-link {
	display: block;
	font-size: 18px;
	font-weight: bold;
	color: @dark-font;
	text-decoration: none;
	margin: 1em 0;

	&:hover,
	&:focus {
		color: @oebb-red;
	}

	&:active {
		color: @dark-red;
	}
}

.site-nav p {
	margin: 1em 0;
}



