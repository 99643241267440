.blockquote {
    text-align: center;
}

.blockquote .blockquote__img {
    max-width: 100%;
    width: auto;
}

.blockquote__quote {
    padding: 0 20px;
    font-size: 1.1em;
    font-weight: bold;
}

.blockquote cite {
    display: block;
    max-width: 250px;
    font-style: normal;
    font-size: .85em;
    margin: 0 auto;
}

.blockquote__cite {
    display: block;
    font-weight: bold;
    text-transform: uppercase;
}

.blockquote__sub-cite {
    display: block;
}