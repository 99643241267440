.video-container {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 ratio */
	height: 0;
	margin: 15px 0;
}

.video-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video-container-portrait {
	min-height: 610px;
}

.video-categories {
	margin-bottom: 20px;
}

a.video-link {
	cursor: pointer;
}

.content .video-tile p {
	text-align: left;
	font-size: 15px;
}

.mashup.tv-mashup ul{
	margin: 0 10px 0 10px;
}

li.video-tile {
	margin-bottom: 20px;
	padding: 0;
	background: transparent none repeat scroll 0 0;
	height: 100%;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	overflow: hidden;
	margin-top: 0 !important;
}

li.video-tile img,
li.video-tile p,
#my-playlist img,
#my-playlist p {
	margin: 0;
}

li.video-tile img {
	width: 100%;
	height: auto;
	position: relative;
}

li.video-tile p,
#my-playlist p {
	color: @black;
	font-size: 15px;
}

li.video-tile p {
	margin-top: 5px;
}

.video-tile p.video-download,
#my-playlist p.video-download {
	float: right;
	margin-top: -20px;
}

li.video-tile a.video-link div.video-duration,
li.video-tile a.video-link div.video-operator-my-playlist {
	color: @white;
	padding: 2px;
	opacity: 0.75;
	font-weight: bold;
	position: absolute;
	bottom: 5px;
	font-size: 13px;
}

li.video-tile a.video-link div.video-duration {
	background: @black;
	left: 5px;
}

li.video-tile a.video-link div.video-operator-my-playlist {
	background: @oebb-red;
	right: 5px;
	width: 20px;
	text-align: center;
}

li.video-tile:focus {
	outline: 1px dotted @black-70;
	outline-offset: 1px;
}

li.video-tile a.video-link div.video-operator-my-playlist:focus {
	background: @white;
	color: @oebb-red;
}

li.video-tile p.video-download a:focus {
	outline: 1px dotted @black-70;
	outline-offset: -1px;
}

div.video-thumbnail-container {
	position: relative;
}

#my-playlist ul.teaser-slider-list {
	height: 100% !important;
}

#my-playlist a.video-link div.video-duration,
#my-playlist a.video-link div.video-operator-my-playlist {
	color: @white;
	padding: 2px;
	opacity: 0.75;
	font-weight: bold;
	position: absolute;
	bottom: 7px;
	font-size: 13px;
}

#my-playlist a.video-link div.video-duration {
	background: @black;
	left: 3px;
}

#my-playlist a.video-link div.video-operator-my-playlist {
	background: @oebb-red;
	right: 3px;
	width: 20px;
	text-align: center;
}

#my-playlist a.video-link div.video-operator-my-playlist:focus {
	background: @white;
	color: @oebb-red;
}

#my-playlist p.video-download a {
	color: @oebb-red;
}

#my-playlist p.video-download a:hover,
#my-playlist p.video-download a:focus{
	color: @dark-red;
}

#my-playlist p.video-download a:focus{
	outline: 1px dotted @black-70;
}

.video-preview {
	position: relative;

	overflow: hidden;

	cursor: pointer;

	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;

		content: '';
		display: block;
		width: 100%;
		height: 100%;

		background-color: #000000;
		opacity: 0.7;
	}
}

.video-preview__play {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 10;

	content: '';
	display: block;

	transform: translate(-50%, -50%);

	color: #ffffff;
	font-size: 10em;
}

video {
	width: 100%;
	max-width: 100% !important;
	height: auto;
}

.video-container-outer.video--ready {
    border: 1px solid #E4E4E4;
    padding: 0 15px 15px;
    margin-bottom: 20px;

	.video-container {
		display: none;
	}

    &.video--activated {
        border: none;
		padding: 0;
		
		.video-container {
			display: block;
		}

        button {
			color: @content-link-red;
            background: transparent;
            border-color: transparent;
            text-decoration: underline;
            padding: 0;
            margin: -5px 0 20px auto;
            min-width: 0;
            min-height: 0;

            &:hover,
            &:focus {
                color: #666;
            }
        }
    }

    button {
        margin-top: 0;
		margin-bottom: 0;
		display: block;
		margin-left: auto;
    }
}

.video-container .video__disclaimer {
    margin-bottom: 10px;
}

.video--activated .video__disclaimer {
    display: none;
}

img.video-preview-image {
	margin: 0;
}
