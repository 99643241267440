.social-row {
	text-align: right;
	padding: 20px 0;
}

.social-row ul {
	list-style-type: none;
	list-style: none;
	display: inline-block;
	margin: 0;
	padding: 0;
}

.social-row li {
	display: inline-block;
	position: relative;
	float: left;
}

.social-row li {
	padding-left: 6px;
}

.social-row li a:focus, .social-row li a:hover {
	opacity: 0.7;
}
.social-row li a:focus{
	outline:1px dotted black;
}

.social-row li a:active {
	opacity: 1;
}
.social-row li a img{
	width: 38px;
	height: 38px
}

@media @medium-devices-and-smaller  {
	.social-row,
	.social-row ul {
		text-align: center;
	}

	.social-row li {
		float: none;
	}
}

.social-row.social-row--icon-font {
	a {
		text-decoration: none;
		color: @oebb-red;
		width: auto;

		&:before {
			font-size: 40px;
		}

		&.rcg-icon-blog {
			margin-top: -1px;

			&:before {
				font-size: 42px;
			}
		}

		&:hover,
		&:focus {
			color: @dark-red;
		}
	}
}
